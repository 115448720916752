import { Modal, Spin, Upload, notification } from "antd";
import uploadImageIcon from "assets/images/oc-browser.svg";
import { useFormik } from "formik";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as facilityDepActions from "redux/common/facility-department/Actions";
import * as FacilitySpecialityActions from "redux/common/facility-speciality/Actions";
import * as faqsActions from "redux/common/faqs/Actions";
import * as cityAction from "redux/city/Actions";
import * as serviceLanguageActions from "redux/common/service-language/Actions";
import * as servicePriceActions from "redux/common/service-price/Actions";
import * as serviceActions from "redux/common/service/Actions";
import * as whyChooseUsActions from "redux/common/why-choose-us/Actions";
import * as countryAction from "redux/country/Actions";
import * as doctorActions from "redux/doctor/Actions";
import * as actions from "redux/facility/Actions";
import * as specialitiesActions from "redux/specialities/Actions";
import * as treatmentActions from "redux/treatment/Actions";
import {
  JsonToCSV,
  convertUnicodes,
  downloadFile,
  sanitizeString,
  validateFileExtension,
} from "utils/helpers/helper-functions";
import * as Yup from "yup";
import FacilityBulk from "./components/common/facility-bulk";
import { v4 as uuidv4 } from "uuid";

export const FacilityBulkModal = (props) => {
  const { setIsModalOpen, isModalOpen, getPagination } = props;
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const dispatch = useDispatch();

  const {
    loading,
    serviceLanguagesRedux = [],
    facilitySpecialityRedux = [],
    faqsRedux = [],
    countryRedux = [],
    cityRedux = [],
    serviceRedux = [],
    whyChooseUsRedux = [],
    facilityDepartmentRedux = [],
    doctorRedux = [],
    servicePriceRedux = [],
    // specialityRedux = [],
  } = useSelector(
    (state) => ({
      loading: state.facility?.loading?.bulk,
      serviceLanguagesRedux: state.serviceLanguage?.records,
      facilitySpecialityRedux: state.facilitySpeciality?.records,
      faqsRedux: state.faqs?.records,
      countryRedux: state.country?.records,
      cityRedux: state.city?.records,
      serviceRedux: state.service?.records,
      whyChooseUsRedux: state.whyChooseUs?.records,
      facilityDepartmentRedux: state.facilityDepartment?.records,
      doctorRedux: state.doctor?.records,
      servicePriceRedux: state.servicePrice?.records,
      specialityRedux: state.speciality?.records,
    }),
    shallowEqual
  );

  const closeModal = () => {
    setFile(null);
    setFileData(null);
    formik.setFieldValue("facility", []);
    setIsModalOpen(false);
  };

  const prop = {
    name: "file",
    multiple: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    maxCount: 1,
    onChange(info) {
      if (!info?.file) return;
      const { status } = info?.file;
      if (status !== "uploading") {
        setFile(info?.file);
        csvToJsonParser(info?.file);
      }
      if (status === "removed") {
        setFile(null);
        setFileData(null);
      }
    },
    beforeUpload: (file) => validateFileExtension(file, ["csv"]),
  };

  const csvToJsonParser = (file) => {
    return Papa.parse(file?.originFileObj, {
      header: true,
      skipEmptyLines: true,
      complete: async ({ data = [] }) => {
        if (data?.length) {
          await relationalDataHandler(data);
          setFileData(data);
        }
      },
      error: (error) => console.error("Error parsing  CSV:", error.message),
    });
  };

  function removeDuplicates(arr) {
    const uniqueNames = new Set();
    return arr.filter((obj) => {
      if (!uniqueNames.has(obj.name_en)) {
        uniqueNames.add(obj.name_en);
        return true;
      }
      return false;
    });
  }

  const saveFileData = async () => {
    if (!fileData.length) return;
    const relationalData = await fetchRelationalData(fileData || []);

    const facility = fileData
      .map((entity) =>
        Object.fromEntries(
          Object.keys(mockFacilityObject).map((key) => [
            key,
            entity?.[key] || "",
          ])
        )
      )
      .filter((p) => Object.values(p).some((v) => v?.trim() !== ""))
      .map((entity, index) => {
        let bed = "0";
        if (entity?.bed?.trim() !== "n/a" && entity?.bed?.trim() !== "") {
          bed = entity?.bed?.toString()?.trim();
        }

        let department =
          relationalData[index]?.departmentIds.length.toString() || "0";

        let doctor = "0";
        if (entity?.doctor?.trim() !== "n/a" && entity?.doctor?.trim() !== "") {
          doctor = entity?.doctor?.toString()?.trim();
        }
        let yearly_patient = "0";
        if (
          entity?.yearly_patient?.trim() !== "n/a" &&
          entity?.yearly_patient?.trim() !== ""
        ) {
          yearly_patient = entity?.yearly_patient
            ?.toString()
            ?.trim()
            .replace(",", "");
        }
        let foundation = "0";
        if (
          entity?.foundation?.trim() !== "n/a" &&
          entity?.foundation?.trim() !== ""
        ) {
          foundation = entity?.foundation?.toString()?.trim();
        }

        let type =
          entity?.type?.trim() !== ""
            ? entity.type?.trim().toLowerCase()
            : "hospital";

        return {
          ...entity,
          type: type?.trim(),
          foundation: foundation,
          overview_en: entity?.overview || "No overview provided",
          name_en: entity?.name,
          address_en: entity?.address,
          department: department,
          bed: bed,
          doctor: doctor,
          yearly_patient: yearly_patient,
          description_en: "",
          lat: "",
          long: "",
          isFeatured: true,
          isActive: true,

          zipCode: sanitizeString(entity?.zipCode).length
            ? entity?.zipCode
            : "0",
          ...(relationalData[index] || {}),
        };
      });

    formik.setFieldValue("facility", facility);
  };

  const relationalDataHandler = async (data) => {
    if (!data?.length) return [];
    await Promise.all([
      addNewFaqs(data),
      addNewCountry(data),
      addNewServiceLang(data),
      // addNewSpecialties(data),
      addNewCity(data),
      addNewServices(data),
      addNewWhyUs(data),
      addNewServicePrice(data),
      addNewDepartment(data),
    ]);
  };

  const fetchRelationalData = (data) => {
    return data.map((entity) => {
      const {
        serviceLanguages = "",
        specialities = "",
        faqQuestions = "",
        country = "",
        city = "",
        services = "",
        whyChooseUs = "",
        // departments = "",
        doctors = "",
        serviceName = "",
        // achievements = "",
        // serviceType = "",
        // servicePrice = "",
      } = entity;

      const mediaUrlsInput = entity?.mediaUrls?.split(";");

      const mediaUrls = [];

      for (let i = 0; i < mediaUrlsInput?.length; i++) {
        const current = sanitizeString(mediaUrlsInput[i]);
        if (current && current.length > 0) {
          mediaUrls.push(current);
        }
      }

      const achievementsInput = entity?.achievements?.split(";");

      const achievementList = [];

      for (let i = 0; i < achievementsInput?.length; i++) {
        const current = sanitizeString(achievementsInput[i]);
        if (current && current.length > 0) {
          achievementList.push(current);
        }
      }

      const departmentIds = [];

      let depNamesAndDesc = entity?.departmentdescription?.split(";") || [];
      for (let depName of depNamesAndDesc) {
        // Ensure depName is not null or undefined
        depName = depName.trim();
        depName = sanitizeString(depName); // Sanitize the string
        depName = depName.split(":")[0] || ""; // Extract the relevant part after ":" if exists
        depName = depName.trim(); // Trim any leading or trailing whitespace

        const matchingDepartment = facilityDepartmentRedux.find(
          (reduxDep) => reduxDep.name_en === entity.name.trim() + " " + depName
        );

        if (
          matchingDepartment &&
          !departmentIds.includes(matchingDepartment.id)
        ) {
          departmentIds.push(matchingDepartment.id);
        }
      }

      return {
        mediaUrls,
        departmentIds,
        achievements: achievementList,
        servicePriceIds: fetchIds(serviceName, servicePriceRedux, "name_en"),
        doctorIds: fetchDocIds(doctors, doctorRedux),
        cityId: cityRedux.find(
          ({ name_en }) => sanitizeString(name_en) === sanitizeString(city)
        )?.id,
        countryId: countryRedux.find(
          ({ name_en }) => sanitizeString(name_en) === sanitizeString(country)
        )?.id,
        faqIds: fetchIds(faqQuestions, faqsRedux, "question_en"),
        serviceLanguageIds: fetchIds(
          serviceLanguages,
          serviceLanguagesRedux,
          "name_en"
        ),
        specialityIds: fetchIds(
          specialities,
          facilitySpecialityRedux,
          "name_en"
        ),
        serviceIds: fetchIds(services, serviceRedux, "name_en"),
        whyChooseUsIds: fetchWhyChooseusIds(whyChooseUs, whyChooseUsRedux),
      };
    });
  };
  // -------- saving data to Db ------------- //
  const addNewCity = (data) => {
    const city = [
      ...new Map(
        data?.map(({ city = "", country = "" }) => [
          sanitizeString(city),
          {
            name_en: sanitizeString(city),
            countryName: sanitizeString(country),
          },
        ])
      ).values(),
    ].filter(({ name_en }) => name_en);

    const newRecord = city?.filter(
      ({ name_en: name }) =>
        !cityRedux?.find(({ name_en }) => sanitizeString(name_en) === name)
    );
    const payload = newRecord
    // eslint-disable-next-line
      .map(({ name_en, countryName }) => {
        const country = countryRedux?.find(
          (country) =>
            sanitizeString(country?.name_en) === sanitizeString(countryName)
        );

        if (country) {
          return { name_en, countryId: country?.id };
        }
      })
      .filter(Boolean);
    payload.length && dispatch(cityAction.bulk(payload, ""));
  };

  const addNewCountry = (data) => {
    let extractedOptions = [
      ...new Set(
        data?.map(({ country }) => sanitizeString(country)).filter(Boolean)
      ),
    ];

    const records = extractedOptions?.filter(
      (name) =>
        !countryRedux?.find(({ name_en }) => sanitizeString(name_en) === name)
    );

    if (records.length) {
      const payload = records.map((name_en) => ({ name_en, code: "" }));

      dispatch(countryAction.bulk(payload, ""));
    }
  };

  const addNewFaqs = (data) => {
    const faqQuestions = [
      ...new Set(
        data?.flatMap((e) => e?.faqQuestions?.split(";")).filter(Boolean)
      ),
    ];
    const faqAnswers = [
      ...new Set(
        data?.flatMap((e) => e?.faqAnswers?.split(";")).filter(Boolean)
      ),
    ];
    const faqs = [
      ...new Map(
        faqQuestions.map((question_en, index) => [
          sanitizeString(question_en),
          {
            question_en: sanitizeString(question_en),
            answer_en: faqAnswers[index] || "  ",
          },
        ])
      ).values(),
    ];

    const newRecord = faqs?.filter(
      ({ question_en: question }) =>
        !faqsRedux?.find(
          ({ question_en }) => question_en === sanitizeString(question)
        )
    );

    newRecord.length && dispatch(faqsActions.bulk(newRecord, ""));
  };

  const addNewDepartment = async (data) => {
    try {
      const extractedOptions = [
        ...new Set(
          data
            ?.flatMap((item) =>
              item?.departmentdescription
                ?.split(";")
                .filter((n) => n !== "")
                .map((dep) => `${item.name.trim()} ${dep.trim()}`)
                .filter(Boolean)
            )
            .map((str) => sanitizeString(str?.replace(/\n/g, "")?.trim()))
            .filter(Boolean)
        ),
      ];

      const nameAndDesc = extractedOptions.map((opt) => {
        const [name, description = ""] = opt
          .split(":")
          .map((part) => part.trim());
        return {
          name_en: name,
          detail_en: convertUnicodes(description.replace(/\n/g, "")),
        };
      });

      const newRecords = nameAndDesc.filter(
        ({ name_en }) =>
          !facilityDepartmentRedux.some(
            ({ name_en: reduxName }) =>
              sanitizeString(reduxName) === sanitizeString(name_en)
          )
      );

      if (newRecords.length) {
        const facilityDepartmentQuery = `limit=1000000&offset=0`;
        dispatch(
          facilityDepActions.bulk(
            newRecords.map((record) => ({ ...record, id: uuidv4() })),
            { query: facilityDepartmentQuery }
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const addNewSpecialties = (data) => {
  //   let extractedOptions = [
  //     ...new Set(
  //       data
  //         ?.flatMap(({ specialities }) => specialities?.split(";"))
  //         .filter(Boolean)
  //     ),
  //   ].map((item) => sanitizeString(item));

  //   const records = extractedOptions?.filter(
  //     (name) =>
  //       !facilitySpecialityRedux?.find(
  //         ({ name_en }) => sanitizeString(name_en) === name
  //       )
  //   );

  //   const newRecords = removeDuplicates(records);

  //   if (newRecords.length) {
  //     const payload = newRecords.map((name_en) => ({ name_en }));
  //     dispatch(facilitySpecialityActions.bulk(payload, ""));
  //   }
  // };

  const addNewServiceLang = (data) => {
    let extractedOptions = [
      ...new Set(
        data
          ?.flatMap(({ serviceLanguages }) => serviceLanguages?.split(";"))
          .filter(Boolean)
      ),
    ].map((language) => sanitizeString(language));

    const recordsInitial = extractedOptions?.filter(
      (name) =>
        !serviceLanguagesRedux?.find(
          ({ name_en }) => sanitizeString(name_en) === name
        )
    );

    const records = removeDuplicates(recordsInitial);

    if (records.length) {
      const payload = records.map((name_en) => ({ name_en }));
      dispatch(serviceLanguageActions.bulk(payload, ""));
    }
  };

  const addNewServices = (data) => {
    let extractedOptions = [
      ...new Set(
        data?.flatMap((item) => item?.services?.split(";")).filter(Boolean)
      ),
    ].map((language) => sanitizeString(language));

    const records = extractedOptions?.filter(
      (name) =>
        !serviceRedux?.find(({ name_en }) => sanitizeString(name_en) === name)
    );

    const newRecords = removeDuplicates(records);

    if (newRecords?.length) {
      const payload = newRecords.map((name_en) => ({ name_en }));
      dispatch(serviceActions.bulk(payload, ""));
    }
  };

  const addNewWhyUs = (data, reduxList) => {
    let extractedOptions = [
      ...new Set(
        data
          ?.filter((d) => d !== "")
          .flatMap((item) => item?.whyChooseUs?.split(";"))
          .filter(Boolean)
      ),
    ].map((language) => sanitizeString(language));

    extractedOptions = extractedOptions.filter((e) => e?.trim() !== "");

    const records = extractedOptions?.filter(
      (text) =>
        !reduxList?.find(({ text_en }) => text_en === sanitizeString(text))
    );
    if (records.length) {
      const payload = records.map((text_en) => ({
        text_en: sanitizeString(text_en.replace("-", "").trim()),
      }));
      const whyQuery = `limit=1000000&offset=0`;

      dispatch(whyChooseUsActions.bulk(payload, { query: whyQuery }));
    }
  };

  const addNewServicePrice = (data) => {
    let serviceName = [
      ...new Set(
        data?.flatMap((item) => item?.serviceName?.split(";")).filter(Boolean)
      ),
    ].map((e) => sanitizeString(e));

    let servicePrice = [
      ...new Set(
        data?.flatMap((item) => item?.servicePrice?.split(";")).filter(Boolean)
      ),
    ].map((e) => sanitizeString(e.replace(/[^0-9]/g, "")));

    let serviceType = [
      ...new Set(
        data?.flatMap((item) => item?.serviceType?.split(";")).filter(Boolean)
      ),
    ].map((e) => sanitizeString(e));

    const extractedOptions = [
      ...new Map(
        serviceName.map((name, index) => [
          sanitizeString(name),
          {
            name_en: sanitizeString(name),
            type: serviceType[index] || "diagnosis",
            price: servicePrice[index] || "0",
          },
        ])
      ).values(),
    ];

    const records = extractedOptions?.filter(
      (e) =>
        !servicePriceRedux?.find(
          ({ name_en }) => name_en === sanitizeString(e.name_en)
        )
    );

    records.length && dispatch(servicePriceActions.bulk(records, ""));
  };

  const onSubmit = () => {
    const payload = formik?.values?.facility;

    if (!payload.length) {
      return notification.error({ description: "No record to save" });
    }
    const pagination = getPagination();
    return dispatch(actions.bulk(payload, pagination, closeModal));
  };

  const download = (e) => {
    e.stopPropagation();
    const content = JsonToCSV([mockFacilityObject]);
    downloadFile("bulk-facility.csv", content);
  };

  const formik = useFormik({
    initialValues: { facility: [] },
    validationSchema,
    onSubmit,
  });

  const getMasterData = () => {
    const query = `limit=1000000&offset=0`;
    const doctorQuery = `limit=1000000&offset=0&relations[]=city&relations[]=city.country&relations[]=doctorSpeciality&relations[]=doctorSpeciality.speciality`;
    const facilityDepartmentQuery = `limit=1000000&offset=0`;
    const countryQuery = `limit=1000000&offset=0&relations[]=city`;

    Promise.all([
      dispatch(countryAction.getAll({ query: countryQuery })),
      dispatch(cityAction.getAll({ query: query })),
      dispatch(specialitiesActions.getAll({ query })),
      dispatch(treatmentActions.getAll({ query })),
      dispatch(whyChooseUsActions.getAll({ query })),
      dispatch(servicePriceActions.getAll({ query })),
      dispatch(faqsActions.getAll({ query })),
      dispatch(serviceActions.getAll({ query })),
      dispatch(serviceLanguageActions.getAll({ query })),
      dispatch(FacilitySpecialityActions.getAll({ query: query })),
      dispatch(doctorActions.getAll({ query: doctorQuery })),
      dispatch(facilityDepActions.getAll({ query: facilityDepartmentQuery })),
    ]);
  };

  useEffect(() => {
    fileData?.length && saveFileData();
  }, 
  // eslint-disable-next-line
  [
    fileData,
    serviceLanguagesRedux,
    facilitySpecialityRedux,
    faqsRedux,
    countryRedux,
    cityRedux,
    serviceRedux,
    whyChooseUsRedux,
    facilityDepartmentRedux,
    doctorRedux,
    servicePriceRedux,
    facilitySpecialityRedux,
  ]);

  useEffect(() => {
    isModalOpen && getMasterData();
  }, 
  // eslint-disable-next-line
  [isModalOpen]);

  return (
    <>
      <Modal
        width={"80%"}
        footer={""}
        title=" Bulk Upload"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {file ? (
                <div>
                  <div
                    className="py-2 d-flex justify-content-between  align-items-center mt-2 mb-0 px-2"
                    style={{
                      background: "#fafafa",
                      borderRadius: "3px",
                      border: "1px dashed #d9d9d9",
                    }}
                  >
                    <div className="">{file && file?.originFileObj?.name}</div>
                    <div>
                      {" "}
                      <button
                        onClick={() => {
                          setFile(null);
                          formik.setFieldValue("facility", []);
                        }}
                        className="btn btn-white text-danger btn-icon btn-sm show"
                      >
                        <i className="bi-trash "></i>
                      </button>
                    </div>
                  </div>
                  <>
                    <div className="mt-4">
                      <FacilityBulk formik={formik} />

                      <div className=" mt-3 text-center">
                        <button
                          disabled={loading}
                          onClick={closeModal}
                          type="button"
                          className="btn btn-white px-5"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={
                            !file || loading || formik?.errors?.facility?.length
                          }
                          type="button"
                          className={`btn mx-2 px-5 ${
                            loading ? "btn-white" : "btn-theme"
                          }`}
                          onClick={onSubmit}
                        >
                          {loading ? <Spin /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ) : (
                <>
                  <Upload.Dragger {...prop}>
                    <>
                      <div className="dz-message">
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={uploadImageIcon}
                          alt="Description"
                          data-hs-theme-appearance="default"
                        />
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={uploadImageIcon}
                          alt="Description"
                          data-hs-theme-appearance="dark"
                        />

                        <h5>Drag and drop your file here</h5>

                        <p className="mb-2">or</p>

                        <span className="btn btn-white btn-sm">
                          Browse files
                        </span>

                        <div className="mt-2">
                          <span className="anchor-color" onClick={download}>
                            Download Template
                          </span>
                        </div>
                      </div>
                    </>
                  </Upload.Dragger>
                </>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

var mockFacilityObject = {
  name: "john doe",
  address: "123 main street",
  zipCode: "12345",
  country: "pakistan",
  city: "kpk",
  type: "hospital",
  email: "john.doe@example.com",
  phone: "1234567890",
  foundation: "1998",
  department: "28",
  bed: "96",
  doctor: "39",
  yearly_patient: "2689",
  overview: "a brief overview of the professional.",
  serviceLanguages: "English;sindhi;Urdu",
  specialities: "new",
  faqQuestions: "Question 1;  Question 33",
  faqAnswers: "Answer 1;  Answer 2",
  services: "Internet;food",
  departments: "surgery",
  whyChooseUs: "test",
  doctors: "test@gmail.com;john.doe@example.com",
  serviceName: "test,test-3",
  serviceType: "diagnosis, diagnosis",
  servicePrice: "1,2",
};

var validationSchema = Yup.object({
  facility: Yup.array(
    Yup.object().shape({
      name_en: Yup.string().required(" Name (English) is required"),
      address_en: Yup.string().required("Address (English) is required"),
      zipCode: Yup.string().required("zipCode is required").min(1),
      countryId: Yup.string().notRequired(),
      cityId: Yup.string().required("City is required"),
      isFeatured: Yup.boolean().notRequired(),
      isActive: Yup.boolean().notRequired(),
      phone: Yup.string(),
      email: Yup.string().notRequired().email(),
      type: Yup.string().required(),
      lat: Yup.string().notRequired("Latitude is required"),
      long: Yup.string().notRequired("Longitude is required"),
      foundation: Yup.number()
        .typeError("Must be a number")
        .min(0)
        .max(2024)
        .notRequired(),
      // .required("Year of Foundation is required"),
      department: Yup.number().typeError("Must be a number").notRequired(),
      // .required("No. of Departments is required"),
      bed: Yup.number().typeError("Must be a number").notRequired(),
      // .required("No. of Beds is required"),
      doctor: Yup.number().typeError("Must be a number").notRequired(),
      // .required("No. of  Doctors Patient is required"),
      yearly_patient: Yup.number().typeError("Must be a number").notRequired(),
      // .required("No. of  Yearly Patients is required"),

      overview_en: Yup.string().required("Overview (English) is required"),

      doctorIds: Yup.array().notRequired(),
      servicePriceIds: Yup.array().notRequired(),
      serviceIds: Yup.array().notRequired(),
      faqIds: Yup.array().notRequired(),
      whyChooseUsIds: Yup.array().notRequired(),
      serviceLanguageIds: Yup.array().notRequired(),
      departmentIds: Yup.array().notRequired(),
      specialityIds: Yup.array().notRequired(),
    })
  ),
});

// var optionalCsvFields = {
//   cityId: "",
//   countryId: "",
//   lat: "",
//   long: "",
//   description_en: "",
//   doctorIds: [],
//   servicePriceIds: [],
//   servicesIds: [],
//   faqIds: [],
//   whyChooseUsIds: [],
//   departmentIds: [],
//   serviceLanguageIds: [],
//   specialityIds: [],
//   isActive: true,
//   isFeatured: true,
// };

var fetchIds = (list, reduxList, property) =>
  list
    ?.split(";")
    .map(
      (name) =>
        reduxList.find(
          (item) => sanitizeString(item[property]) === sanitizeString(name)
        )?.id
    )
    .filter(Boolean);

var fetchWhyChooseusIds = (list, reduxList) => {
  return list
    ?.split(";")
    .filter(Boolean)
    ?.map((language) => sanitizeString(language))
    ?.filter((e) => e?.trim() !== "")
    ?.map(
      (name) =>
        reduxList.find(
          (item) =>
            item.text_en === sanitizeString(name?.replace("-", "").trim())
        )?.id
    )
    .filter(Boolean);
};

var fetchDocIds = (list, reduxList) =>
  list
    ?.split(";")
    .map(
      (name) =>
        reduxList.find(
          (item) =>
            sanitizeString(item.firstName_en + " " + item.lastName_en) ===
            sanitizeString(name)
        )?.id
    )
    .filter(Boolean);
