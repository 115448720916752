import axios from "axios";
import { ApiUrl } from "services/api";

const { Disease: DiseaseApi } = ApiUrl;

// export const getAll = async ({ query }) =>
//   axios[DiseaseApi.getAll.method](DiseaseApi.getAll.url(query));

export const getAll = async ({ query }) => {
  try {
    const response = await axios[DiseaseApi.getAll.method](DiseaseApi.getAll.url(query));
    const dataWithKeys = response.data.records.map((item, index) => ({
      ...item,
      key: index 
    }));

    // Create a new object with the modified data array and root-level properties
    const modifiedResponse = {
      data: {
        count: response.data.count,
        offset: response.data.offset,
        limit: response.data.limit,
        records: dataWithKeys
      }
    };
    return modifiedResponse;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error to propagate it further
  }
};


export const create = async (data) =>
  axios[DiseaseApi.create.method](DiseaseApi.create.url(), data);

export const remove = async (id) =>
  axios[DiseaseApi.remove.method](DiseaseApi.remove.url(id));

export const update = async (id, data) =>
  axios[DiseaseApi.update.method](DiseaseApi.update.url(id), data);
