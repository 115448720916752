import { useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import { DoctorBulkModal } from "./doctor.bulk.modal";
import DoctorsDrawer from "./doctor.drawer";
import DoctorsTable from "./doctor.table";
import { doctorRelations as relations } from "services/relations";

function Doctor() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [view, setView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    setIsModalOpen,
    isModalOpen,
    recordId,
    setRecordId
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Doctors</h1>
        <div className="buttons-on-title  mx-5">
          <button
            onClick={() => setIsModalOpen(true)}
            className="btn btn-theme"
          >
            <i className="bi-cloud-arrow-up-fill me-1"></i> Bulk Upload
          </button>
          <button
            className="btn mx-2 btn-theme"
            onClick={() => setDrawer(true)}
          >
            Add New Doctor
          </button>
        </div>
      </div>
      <DoctorsTable {...commonProps} />
      <DoctorsDrawer {...commonProps} />
      <DoctorBulkModal {...commonProps} />
    </div>
  );
}

export default Doctor;
