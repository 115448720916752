import { Drawer, Space, Spin } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useFormik } from "formik";
import React, { useEffect, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "redux/content/Actions";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import Select from "react-select";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";

function FilterContentDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;

  const {
    overview_en = editRecord?.content?.overview_en || null,
    overview_ar = editRecord?.content?.overview_ar || null,
    overview_ru = editRecord?.content?.overview_ru || null,
    overview_de = editRecord?.content?.overview_de || null,
    overview_uk = editRecord?.content?.overview_uk || null,
    metaTitle_en = editRecord?.content?.metaTitle_en || null,
    metaTitle_ar = editRecord?.content?.metaTitle_ar || null,
    metaTitle_ru = editRecord?.content?.metaTitle_ru || null,
    metaTitle_de = editRecord?.content?.metaTitle_de || null,
    metaTitle_uk = editRecord?.content?.metaTitle_uk || null,
    metaKeyword_en = editRecord?.content?.metaKeyword_en || null,
    metaKeyword_ar = editRecord?.content?.metaKeyword_ar || null,
    metaKeyword_ru = editRecord?.content?.metaKeyword_ru || null,
    metaKeyword_de = editRecord?.content?.metaKeyword_de || null,
    metaKeyword_uk = editRecord?.content?.metaKeyword_uk || null,
    metaDescription_en = editRecord?.content?.metaDescription_en || null,
    metaDescription_ar = editRecord?.content?.metaDescription_ar || null,
    metaDescription_de = editRecord?.content?.metaDescription_de || null,
    metaDescription_ru = editRecord?.content?.metaDescription_ru || null,
    metaDescription_uk = editRecord?.content?.metaDescription_uk || null,
    countryId = editRecord?.content?.countryId || null,
    cityId = editRecord?.content?.cityId || null,
    specialityId = editRecord?.content?.specialityId || null,
    treatmentId = editRecord?.content?.treatmentId || null,
    doctorId = editRecord?.content?.doctorId || null,
    facilityId = editRecord?.content?.facilityId || null,
    diseaseId = editRecord?.content?.diseaseId || null,
    id =  editRecord?.content?.id || null,
  } = editRecord || {};

  const {
    records,
    country,
    city,
    facility,
    doctor,
    treatment,
    disease,
    loading,
  } = useSelector(
    (state) => ({
      records: state.speciality?.records || [],
      country: state.country?.records || [],
      city: state.city?.records || [],
      facility: state.facility?.records || [],
      doctor: state.doctor?.records || [],
      treatment: state.treatment?.records || [],
      disease: state.disease?.records || [],
      loading: state.speciality?.loading,
    }),
    shallowEqual
  );

  const { Segment, language } = LanguageSegment();
  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${view ? "View" : isEditing ? " Edit " : " Add "}  Content`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const setEditDefaultValues = () => formik.setValues({ ...initialValues });
  const closeDrawer = () => setDrawer(false);
  const [speciality_ids, setSpeciality_ids] = useState([]);
  const [facilities_ids, setFacilities_ids] = useState([]);
  const [doctors_ids, setDoctors_ids] = useState([]);
  const [treatment_ids, setTreatment_ids] = useState([]);
  const [disease_ids, setDisease_ids] = useState([]);
  const [country_ids, setCountry_ids] = useState([]);
  const [city_ids, setCity_ids] = useState([]);
  const [open, setOpen] = useState(false);


  const resetForm = () => {
    formik.resetForm({
      values: {
        overview_en: "",
        overview_ar: "",
        overview_ru: "",
        overview_de: "",
        overview_uk: "",
        metaTitle_en: "",
        metaTitle_ar: "",
        metaTitle_ru: "",
        metaTitle_de: "",
        metaTitle_uk: "",
        metaKeyword_en: "",
        metaKeyword_ar: "",
        metaKeyword_ru: "",
        metaKeyword_de: "",
        metaKeyword_uk: "",
        metaDescription_en: "",
        metaDescription_ar: "",
        metaDescription_de: "",
        metaDescription_ru: "",
        metaDescription_uk: "",
        countryId: null,
        cityId: null,
        specialityId: null,
        treatmentId: null,
        doctorId: null,
        facilityId: null,
        diseaseId: null,
        id: null,
      },
    });
  };


  const onDrawerChange = (e) => {

    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(
    () => {
      drawer && isEditing && setEditDefaultValues();
    },
    // eslint-disable-next-line
    [drawer]
  );

  //formik Handler
  const initialValues = {
    metaTitle_en: metaTitle_en || "",
    metaTitle_ar: metaTitle_ar || "",
    metaTitle_ru: metaTitle_ru || "",
    metaTitle_de: metaTitle_de || "",
    metaTitle_uk: metaTitle_uk || "",
    metaKeyword_en: metaKeyword_en || "",
    metaKeyword_ar: metaKeyword_ar || "",
    metaKeyword_ru: metaKeyword_ru || "",
    metaKeyword_de: metaKeyword_de || "",
    metaKeyword_uk: metaKeyword_uk || "",
    metaDescription_en: metaDescription_en || "",
    metaDescription_ar: metaDescription_ar || "",
    metaDescription_ru: metaDescription_ru || "",
    metaDescription_de: metaDescription_de || "",
    metaDescription_uk: metaDescription_uk || "",
    overview_en: overview_en || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_de: overview_de || "",
    overview_uk: overview_uk || "",
    countryId: countryId || null,
    cityId: cityId || null,
    specialityId: specialityId || null,
    treatmentId: treatmentId || null,
    doctorId: doctorId || null,
    facilityId: facilityId || null,
    diseaseId: diseaseId || null,
  };

  const validationSchema = Yup.object().shape({
    metaTitle_en: Yup.string().required("Meta Title (English) is required"),
    metaTitle_ar: Yup.string(),
    metaTitle_ru: Yup.string(),
    metaTitle_de: Yup.string(),
    metaTitle_uk: Yup.string(),
    metaKeyword_en: Yup.string(),
    metaKeyword_ar: Yup.string(),
    metaKeyword_ru: Yup.string(),
    metaKeyword_de: Yup.string(),
    metaKeyword_uk: Yup.string(),
    metaDescription_en: Yup.string().required("Description (English) is required"),
    metaDescription_ar: Yup.string(),
    metaDescription_ru: Yup.string(),
    metaDescription_de: Yup.string(),
    metaDescription_uk: Yup.string(),
    overview_en: Yup.string().required("Overview (English) is required"),
    overview_ar: Yup.string(),
    overview_ru: Yup.string(),
    overview_de: Yup.string(),
    overview_uk: Yup.string(),
    countryId: Yup.string().nullable(),
    cityId: Yup.string().nullable(),
    specialityId: Yup.string().nullable(),
    treatmentId: Yup.string().nullable(),
    doctorId: Yup.string().nullable(),
    facilityId: Yup.string().nullable(),
    diseaseId: Yup.string().nullable(),
  });

  const onSubmit = async (values) => {
    setOpen(false)
    const {
      countryId,
      cityId,
      specialityId,
      treatmentId,
      doctorId,
      facilityId,
      diseaseId
    } = values;
  
    // Check if at least one of the fields is not null or empty
    if (
      !countryId?.trim() &&
      !cityId?.trim() &&
      !specialityId?.trim() &&
      !treatmentId?.trim() &&
      !doctorId?.trim() &&
      !facilityId?.trim() &&
      !diseaseId?.trim()
    ) {
      // If none of the fields are filled, set an error message and return early
      formik.setErrors({
        ...formik.errors,
        countryId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        cityId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        specialityId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        treatmentId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        doctorId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        facilityId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',
        diseaseId: 'At least one of Country, City, Speciality, Treatment, Doctor, Facility, or Disease must be selected',

      });
      return;
    }
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));
    const action = editRecord?.content?.id
      ? actions.update(editRecord?.content?.id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const fetchSelectRecords = useCallback(() => {
    setSpeciality_ids(
      records?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );

    setFacilities_ids(
      facility?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );

    setDoctors_ids(
      doctor?.map(({ id: value, [`firstName_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );

    setTreatment_ids(
      treatment?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );

    setDisease_ids(
      disease?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );
    setCountry_ids(
      country?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );
    if(formik.values.countryId){
    setCity_ids(
      city?.filter(entity => formik.values.countryId === entity.countryId)
      ?.map(({ id: value, [`name_${language}`]: label }) => ({
        value,
        label: label || "Translation Not Available",
      })) || []
    );
  }
    
  },
  [disease,doctor,facility,language,records,treatment,country,city,formik.values.countryId]
);

  useEffect(
    () => {
      fetchSelectRecords();
    },
    [language,fetchSelectRecords]
  );

  useEffect(() => {
    if (editRecord && editRecord.content) {
      formik.setValues({
        metaTitle_en: editRecord.content.metaTitle_en || "",
        metaTitle_ar: editRecord.content.metaTitle_ar || "",
        metaTitle_ru: editRecord.content.metaTitle_ru || "",
        metaTitle_de: editRecord.content.metaTitle_de || "",
        metaTitle_uk: editRecord.content.metaTitle_uk || "",
        metaKeyword_en: editRecord.content.metaKeyword_en || "",
        metaKeyword_ar: editRecord.content.metaKeyword_ar || "",
        metaKeyword_ru: editRecord.content.metaKeyword_ru || "",
        metaKeyword_de: editRecord.content.metaKeyword_de || "",
        metaKeyword_uk: editRecord.content.metaKeyword_uk || "",
        metaDescription_en: editRecord.content.metaDescription_en || "",
        metaDescription_ar: editRecord.content.metaDescription_ar || "",
        metaDescription_ru: editRecord.content.metaDescription_ru || "",
        metaDescription_de: editRecord.content.metaDescription_de || "",
        metaDescription_uk: editRecord.content.metaDescription_uk || "",
        overview_en: editRecord.content.overview_en || "",
        overview_ar: editRecord.content.overview_ar || "",
        overview_ru: editRecord.content.overview_ru || "",
        overview_de: editRecord.content.overview_de || "",
        overview_uk: editRecord.content.overview_uk || "",
        countryId: editRecord.content.countryId || null,
        cityId: editRecord.content.cityId || null,
        specialityId: editRecord.content.specialityId || null,
        treatmentId: editRecord.content.treatmentId || null,
        doctorId: editRecord.content.doctorId || null,
        facilityId: editRecord.content.facilityId || null,
        diseaseId: editRecord.content.diseaseId || null,
        id: editRecord.content.id || null,
      });
    }
  }, [editRecord]);
  
  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width="70%"
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div ">
          <h2 className=" text-center">{title}</h2>

          {/* language segment */}
          <div className="language-segmented   px-3 mb-3">{Segment}</div>

          <div className="content container-fluid mt-2 mx-0 px-3">
            <div className="col mx-3">
             {(drawer==='Country' || drawer ==='City') &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Country Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={country_ids?.find( (option) => option.value === formik.values.countryId ) || null} 
                  options={country_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("countryId", selectedOptions.value);
                    formik.setFieldValue("cityId", null);
                  }}
                />
                {formik.errors.countryId && formik.touched.countryId && (
                  <ErrorMessage message={formik.errors.countryId} />
                )}
              </div>
              }
              {drawer==='City' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  City Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={city_ids?.find( (option) => option.value === (formik.values.cityId?formik.values.cityId:editRecord?.id) ) || null} 
                  options={city_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("cityId", selectedOptions.value);
                  }}
                />
                {formik.errors.cityId && formik.touched.cityId && (
                  <ErrorMessage message={formik.errors.cityId} />
                )}
              </div>
                }

            {drawer ==='Facility' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Facility Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={facilities_ids?.find((option) => option.value === formik.values.facilityId ) || null}
                  options={facilities_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("facilityId", selectedOptions.value);
                  }}
                />
                {formik.errors.facilityId && formik.touched.facilityId && (
                  <ErrorMessage message={formik.errors.facilityId} />
                )}
              </div>
              }
              {drawer==='Doctor' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Doctor Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={doctors_ids?.find( (option) => option.value === formik.values.doctorId ) || null} 
                  options={doctors_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("doctorId", selectedOptions.value);
                  }}
                />
                {formik.errors.doctorId && formik.touched.doctorId && (
                  <ErrorMessage message={formik.errors.doctorId} />
                )}
              </div>
              }
              {drawer==='Specilities' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Speciality Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  name="specialityId"
                  value={speciality_ids?.find((option) => option.value === formik.values.specialityId) || null}
                  options={speciality_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("specialityId", selectedOptions.value);
                  }}
                />
                {formik.errors.specialityId && formik.touched.specialityId && (
                  <ErrorMessage message={formik.errors.specialityId} />
                )}
              </div>
              }
              {drawer==='Treatment' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Treatment Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={treatment_ids?.find((option) => option.value === formik.values.treatmentId) || null}
                  options={treatment_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("treatmentId", selectedOptions.value);
                  }}
                />
                {formik.errors.treatmentId && formik.touched.treatmentId && (
                  <ErrorMessage message={formik.errors.treatmentId} />
                )}
              </div>
              }
              {drawer==='Disease' &&
              <div className="my-3">
                <span className="drawer_span  ">
                  Disease Name <span className="form-label-secondary"></span>
                </span>
                <Select
                  isDisabled={view}
                  value={disease_ids?.find((option) => option.value === formik.values.diseaseId) || null}
                  options={disease_ids}
                  className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                  classNamePrefix="select"
                  menuPlacement="bottom"
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("diseaseId", selectedOptions.value);
                  }}
                />
                {formik.errors.diseaseId && formik.touched.diseaseId && (
                  <ErrorMessage message={formik.errors.diseaseId} />
                )}
              </div>
              }
              {language === "en" && (
                <>
                  {" "}
                  <div className="my-2">
                    <span className="drawer_span required">
                      Meta Title{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaTitle_en"
                      className={`form-control ${
                        formik.errors.metaTitle_en &&
                        formik.touched.metaTitle_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle_en}
                      placeholder="Enter Meta Title in (English)"
                    />
                    {formik.errors.metaTitle_en &&
                      formik.touched.metaTitle_en && (
                        <ErrorMessage message={formik.errors.metaTitle_en} />
                      )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span">
                      Meta Keyword{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaKeyword_en"
                      className={`form-control ${
                        formik.errors.metaKeyword_en &&
                        formik.touched.metaKeyword_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeyword_en}
                      placeholder="Enter Meta Keyword in (English)"
                    />
                    {formik.errors.metaKeyword_en &&
                      formik.touched.metaKeyword_en && (
                        <ErrorMessage message={formik.errors.metaKeyword_en} />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span required">
                      Meta Description{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>

                    <RichEditor
                      disabled={view}
                      value={formik.values.metaDescription_en}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("metaDescription_en", value)
                      }
                    />
                    {formik.errors.metaDescription_en &&
                      formik.touched.metaDescription_en && (
                        <ErrorMessage
                          message={formik.errors.metaDescription_en}
                        />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span required">
                      Overview{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>

                    <RichEditor
                      disabled={view}
                      value={formik.values.overview_en}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("overview_en", value)
                      }
                    />
                    {formik.errors.overview_en &&
                      formik.touched.overview_en && (
                        <ErrorMessage message={formik.errors.overview_en} />
                      )}
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "ar" && (
                <>
                  {" "}
                  <div className="my-2">
                    <span className="drawer_span">
                      Meta Title{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaTitle_ar"
                      className={`form-control ${
                        formik.errors.metaTitle_ar &&
                        formik.touched.metaTitle_ar
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle_ar}
                      placeholder="Enter Meta Title in (Arabic)"
                    />
                    {formik.errors.metaTitle_ar &&
                      formik.touched.metaTitle_ar && (
                        <ErrorMessage message={formik.errors.metaTitle_ar} />
                      )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span ">
                      Meta Keyword{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaKeyword_ar"
                      className={`form-control ${
                        formik.errors.metaKeyword_ar &&
                        formik.touched.metaKeyword_ar
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeyword_ar}
                      placeholder="Enter Meta Keyword in (Arabic)"
                    />
                    {formik.errors.metaKeyword_ar &&
                      formik.touched.metaKeyword_ar && (
                        <ErrorMessage message={formik.errors.metaKeyword_ar} />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Meta Description{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <RichEditor
                      value={formik.values.metaDescription_ar}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("metaDescription_ar", value)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <span className="drawer_span ">
                      Overview{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>

                    <RichEditor
                      value={formik.values.overview_ar}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("overview_ar", value)
                      }
                    />
                    {formik.errors.overview_ar &&
                      formik.touched.overview_ar && (
                        <ErrorMessage message={formik.errors.overview_ar} />
                      )}
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "de" && (
                <>
                  {" "}
                  <div className="my-2">
                    <span className="drawer_span">
                      Meta Title{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaTitle_de"
                      className={`form-control ${
                        formik.errors.metaTitle_de &&
                        formik.touched.metaTitle_de
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle_de}
                      placeholder="Enter Meta Title in (German)"
                    />
                    {formik.errors.metaTitle_de &&
                      formik.touched.metaTitle_de && (
                        <ErrorMessage message={formik.errors.metaTitle_de} />
                      )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span ">
                      Meta Keyword{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaKeyword_de"
                      className={`form-control ${
                        formik.errors.metaKeyword_de &&
                        formik.touched.metaKeyword_de
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeyword_de}
                      placeholder="Enter Meta Keyword in (German)"
                    />
                    {formik.errors.metaKeyword_de &&
                      formik.touched.metaKeyword_de && (
                        <ErrorMessage message={formik.errors.metaKeyword_de} />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Meta Description{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <RichEditor
                      value={formik.values.metaDescription_de}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("metaDescription_de", value)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <span className="drawer_span ">
                      Overview{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>

                    <RichEditor
                      value={formik.values.overview_de}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("overview_de", value)
                      }
                    />
                    {formik.errors.overview_de &&
                      formik.touched.overview_de && (
                        <ErrorMessage message={formik.errors.overview_de} />
                      )}
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "ru" && (
                <>
                  {" "}
                  <div className="my-2">
                    <span className="drawer_span">
                      Meta Title{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaTitle_ru"
                      className={`form-control ${
                        formik.errors.metaTitle_ru &&
                        formik.touched.metaTitle_ru
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle_ru}
                      placeholder="Enter Meta Title in (Russian)"
                    />
                    {formik.errors.metaTitle_ru &&
                      formik.touched.metaTitle_ru && (
                        <ErrorMessage message={formik.errors.metaTitle_ru} />
                      )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span ">
                      Meta Keyword{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaKeyword_ru"
                      className={`form-control ${
                        formik.errors.metaKeyword_ru &&
                        formik.touched.metaKeyword_ru
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeyword_ru}
                      placeholder="Enter Meta Keyword in (Russian)"
                    />
                    {formik.errors.metaKeyword_eru &&
                      formik.touched.metaKeyword_ru && (
                        <ErrorMessage message={formik.errors.metaKeyword_ru} />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Meta Description{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <RichEditor
                      value={formik.values.metaDescription_ru}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("metaDescription_ru", value)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <span className="drawer_span ">
                      Overview{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>

                    <RichEditor
                      value={formik.values.overview_ru}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("overview_ru", value)
                      }
                    />
                    {formik.errors.overview_ru &&
                      formik.touched.overview_ru && (
                        <ErrorMessage message={formik.errors.overview_ru} />
                      )}
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "uk" && (
                <>
                  {" "}
                  <div className="my-2">
                    <span className="drawer_span">
                      Meta Title{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaTitle_uk"
                      className={`form-control ${
                        formik.errors.metaTitle_uk &&
                        formik.touched.metaTitle_uk
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaTitle_uk}
                      placeholder="Enter Meta Title in (Ukrainian)"
                    />
                    {formik.errors.metaTitle_uk &&
                      formik.touched.metaTitle_uk && (
                        <ErrorMessage message={formik.errors.metaTitle_uk} />
                      )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span ">
                      Meta Keyword{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="metaKeyword_uk"
                      className={`form-control ${
                        formik.errors.metaKeyword_uk &&
                        formik.touched.metaKeyword_uk
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metaKeyword_uk}
                      placeholder="Enter Meta Keyword in (Ukrainian)"
                    />
                    {formik.errors.metaKeyword_uk &&
                      formik.touched.metaKeyword_uk && (
                        <ErrorMessage message={formik.errors.metaKeyword_uk} />
                      )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Meta Description{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <RichEditor
                      value={formik.values.metaDescription_uk}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("metaDescription_uk", value)
                      }
                    />
                  </div>
                  <div className="my-3">
                    <span className="drawer_span ">
                      Overview{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>

                    <RichEditor
                      value={formik.values.overview_uk}
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("overview_uk", value)
                      }
                    />
                    {formik.errors.overview_uk &&
                      formik.touched.overview_uk && (
                        <ErrorMessage message={formik.errors.overview_uk} />
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default FilterContentDrawer;
