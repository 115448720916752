import { notification, Spin } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import { authSlice } from "../../redux/Auth/authslice";
import { ApiUrl } from "services/api";
import { SUCCESS_MESSAGE, NOTIFICATION } from "utils/constants/contants";
import LOGO from "./../../assets/images/logo.svg";
import LOGO_TITLE_WHITE from "./../../assets/images/logo_title_white.svg";
import "./login.style.css";
import { firebaseLogin } from "../../firebase/firebase";

const { DURATION: duration, PLACEMENT: placement } = NOTIFICATION;
const authAction = authSlice.actions;
const loginApi = ApiUrl.User.logIn;

export default function Login() {
  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  //input validations
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required ")
      .email("Enter correct email format"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be greater than 6 characters"),
  });

  const SubmitHandler = async (values) => {
    setLoading(true);
    try {
      const { email, password } = values;
      const firebaseUser = await firebaseLogin(email, password);
      const firebaseToken = firebaseUser.accessToken;
      const response = await axios[loginApi.method](loginApi.url(), {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      });

      if (response?.status === 200) {
        await api.success({
          message: "Success",
          description:
            response?.data?.message || SUCCESS_MESSAGE.LOGIN_SUCCESSFULLY,
          duration,
          placement,
        });

        const user = response.data;
        localStorage.setItem("token", firebaseToken);
        dispatch(authAction.login({ user, firebaseToken }));
        return navigate("/dashboard");
      }
    } catch (error) {
      notification.error({ description: `Invalid Credentials` });
    } finally {
      setLoading(false);
    }
  };

  //formik Handler
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: LoginSchema,
    onSubmit: SubmitHandler,
  });

  if (authState.firebaseToken && authState.user) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      {contextHolder}
      <main className="login-page-main">
        {/* <!-- Content --> */}
        <div className="container-fluid">
          {/* <!-- row start --> */}
          <div className="row ">
            <div className="col-lg-6 d-flex forget_password_page_min_height forget_password_page_left_div align-items-center ">
              <div className="login_page_left_inner_div    ">
                <img
                  src={LOGO}
                  className=""
                  width="auto"
                  height="81"
                  alt="loading..."
                />
                <form onSubmit={formik.handleSubmit}>
                  <p className="login_page_heading">Login</p>
                  {/* <!-- Email Input  -->*/}
                  <p className="login_left_div_labels_Email text-left">Email</p>
                  <input
                    type="text"
                    className={`login_left_div_input ${
                      formik.errors.email && formik.touched.email
                        ? "input_error"
                        : ""
                    }`}
                    autocomplete="off"
                    placeholder="name@example.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    name="email"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMessage message={formik.errors.email} />
                  )}

                  {/* <!--  Password Input --> */}
                  <p className="login_left_div_labels_password text-left">
                    Password
                  </p>
                  <input
                    type="password"
                    autocomplete="off"
                    className={`login_left_div_input  ${
                      formik.errors.password && formik.touched.password
                        ? "input_error"
                        : ""
                    }`}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    name="password"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <ErrorMessage message={formik.errors.password} />
                  )}

                  <p className="login_left_div_forget_password">
                    <Link to="/forget_password">Forget Password?</Link>
                  </p>

                  <div>
                    <button
                      disabled={loading}
                      type="submit"
                      className={`w-100 btn-sm btn py-3  ${
                        loading ? "btn-loading" : "btn-theme"
                      } px-4 `}
                    >
                      {loading ? <Spin /> : "Login"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="col-lg-6 d-flex  d-none d-lg-flex flex-column justify-content-center align-items-center bg_theme_color_blue login_page_min_height">
              <img
                className="img-fluid"
                src={LOGO_TITLE_WHITE}
                style={{ height: "7rem" }}
                alt="loading..."
              />
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- row end --> */}
        </div>
        {/* <!-- End Content --> */}
      </main>
    </>
  );
}
