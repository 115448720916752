import ServiceDrawer from "app/dashboard/common/service/service.drawer";
import NoDataIcon from "components/animation-icons/no-data-icon";
import { DeleteButton } from "components/button/Buttons";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import { createQueryParams } from "utils/helpers/helper-functions";

function Services({ formik, view }) {
  const { Segment, language } = LanguageSegment();
  const [drawer, setDrawer] = React.useState(false);

  const getPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  //formik Handler
  const { service } = useSelector(
    (state) => ({ service: state.service?.records }),
    shallowEqual
  );

  const services = service?.map(({ id: value, name_en }, index) => ({
    value,
    index,
    label: name_en,
  }));

  return (
    <>
      <ServiceDrawer
        setDrawer={setDrawer}
        drawer={drawer}
        getPagination={getPagination}
      />

      <div className="language-segmented  drawer_area_main_div mx-3 px-3">
        {Segment}
      </div>
      <div className="drawer_area_main_div mx-3 my-2 px-3">
        <span className="pb-2">Services</span>
        <div className="d-flex">
          <Select
            isDisabled={view}
            isMulti
            name="Services"
            value={formik.values.serviceIds}
            options={services}
            onChange={(e) => {
              formik.setFieldValue("serviceIds", e);
            }}
            className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
            classNamePrefix="select"
          />
          <div className="col-sm-auto d-flex align-items-center mx-2">
            <span
              onClick={() => setDrawer(!drawer)}
              className="btn btn-primary btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#newProjectModal"
            >
              <i className="bi-plus me-1"></i> Add
            </span>
          </div>
        </div>
      </div>

      {formik?.values?.serviceIds?.length ? (
        <>
          <div className="services-section-grid-container  drawer_area_main_div mx-3 px-3 my-2">
            {formik.values.serviceIds.map(({ index }, entityIndex) => (
              <>
                <div className="pointer ">
                  <div>
                    <span
                      style={{ marginRight: ".6rem" }}
                      onClick={() =>
                        !view &&
                        formik.setFieldValue(
                          "serviceIds",
                          formik.values.serviceIds.filter(
                            (item, itemIndex) => entityIndex !== itemIndex
                          )
                        )
                      }
                    >
                      <DeleteButton backgroundColor="rgba(113, 134, 157)" />
                    </span>
                  </div>
                  <div className="arrow">
                    {service[index][`name_${language}`] || (
                      <TranslationNotAvailable />
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      ) : (
        <div className="drawer_area_main_div mx-3 px-3 my-2">
          <NoDataIcon />
        </div>
      )}
    </>
  );
}

export default Services;
