import React from "react";

export const DoctorCard = ({
  image = "",
  firstName_en = "",
  lastName_en = "",
  city = "",
  experiance = "",
  doctorSpeciality = [],
}) => (
  <div className="card-body text-center">
    <div className="avatar avatar-xl avatar-soft-primary avatar-circle avatar-centered mb-3">
      <img
        className="avatar-img"
        src={
          image.trim() !== ""
            ? image
            : `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAXVBMVEXd3d1FRUXh4eHDw8NoaGiqqqrj4+OBgYHW1talpaWGhoZ1dXW5ublHR0fY2Njc3NydnZ05OTlZWVlPT0/p6enKyso+Pj7Q0NBeXl44ODhMTEyamppvb29TU1Ozs7N4fB/HAAABdUlEQVR4nO3Z0W6qMACAYSiuQwHBqbixufd/zAHqEt25OYmJW/N9V+Wuf0gphSwDAAAAAAAAAAAAAAAAAAAAAAAAAAB+s3Dj0fO5u8PyWpFaYij6oZnlTTMMTd/FR0/pzkLRvKwuXrfVqqsTu4uhyBfxbFfl9a57q2JSiVPh93gsjE+L1X6fUuJtYQix7o8prcVz4bpcnwuzUG9f2/LR87qfU2F8Po7P0FNh1paH7ZDOXbwUNnNhU8dxz4/rPE9nJV4XbhfVbJsndw+r/n1cgR95k0+aY3KFoaj340VRn3wOyRVmpxfuy8v3uA6TK7xWKvxLFP5934XrctZO4xQL29D10064mQ+HKRbG3XIzFb7tdyHJwlhtut18CP7YdCHRwvd5z4+pFmblYX26ngcpFl5LrjDeapukCpuX5x/SOh/2w/Qx+NqwCVn76KndS7l8+odlOl8xfv6ZSfT/DAAAAAAAAAAAAAAAAAAAAAAAAAAAAPCfvgArgA+aERT4jwAAAABJRU5ErkJggg==`
        }
        alt="Loading..."
      />
    </div>

    <h3 className="mb-1">
      <span className="text-dark" href="#">
        {firstName_en + " " + lastName_en}
      </span>
    </h3>

    <div className="mb-1">
      <i className=" bi-geo-alt-fill me-1"></i>
      <span>
        {city?.name_en}, {city?.country?.name_en}
      </span>
    </div>
    <div className="mb-3">
      <i className="  me-1"></i>
      <span>experiance :{experiance}</span>
    </div>

    <ul className="list-inline mb-0">
      {doctorSpeciality?.map((doctorSpec) => (
        <li className="list-inline-item mt-1">
          <span className="badge bg-soft-secondary text-secondary p-2" >
            {doctorSpec?.speciality?.name_en}
          </span>
        </li>
      ))}
    </ul>
  </div>
);
