import { Pagination, Table ,notification } from "antd";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";
import * as CountryAction from "redux/country/Actions";
import * as treatmentActions from "redux/treatment/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import * as diseaseActions from "redux/diseases/Actions";
import Select from "react-select";
import {
  DeleteButton,
  EditButton} from "components/button/Buttons";

function FilterContentTable(props) {
  const {
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    pageSize,
    setPageSize,
    setPageNumber,
    getPagination,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  const {
    user,
    loading,
    specialityRecords,
    treatmentRecords,
    diseaseRecords,
    countryRecords,
    loadingtreatmentRecords,
    loadingdiseaseRecords,
    relationDiseaseRecords,
    relationTreatmentRecords,
    relationCountDisease,
    relationCountTreatment
    
  } = useSelector(
    (state) => ({
      user: state.auth.user || null,
      loading: state.content?.loading,
      specialityRecords: state.speciality?.records,
      treatmentRecords: state.treatment?.records || [],
      diseaseRecords: state.disease?.records || [],
      countryRecords: state.country?.records || [],      
      loadingtreatmentRecords: state.treatment?.loading,
      loadingdiseaseRecords: state.disease?.loading,
      relationDiseaseRecords: state.disease.relationDiseaseRecords || [],
      relationTreatmentRecords: state.treatment.relationTreatmentRecords || [],
      relationCountDisease: state.disease.relationCount  || 0,
      relationCountTreatment: state.treatment.relationCount  || 0,

    }),
    shallowEqual
  );
  
  const [searchBtn, setSearchBtn] = useState("");
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [specility, setSpecility] = useState("");
  const [specilityName, setSpecilityName] = useState("");
  const [treatment, setTreatment] = useState("");
  const [treatmentName, setTeatmentName] = useState("");
  const [disease, setDisease] = useState("");
  const [countryConditon, setCountryConditon] = useState("");
  const [treatmentCondition, setTreatmentCondition] = useState("");
  const [diseaseCondition, setDiseaseCondition] = useState("");

  useEffect(
    () => {
      let countryQuery = `select[name_en]=true&select[id]=true&limit=1000000&offset=0`;
      let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&`;
      dispatch(CountryAction.getAll({ query: countryQuery }));
      dispatch(specialitiesAction.getAll({ query: specilityQuery }));
      if( specility){
        let treatmentQueryDropdown = `relations[]=content&select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0${treatmentCondition}${countryConditon}`;
        let treatmentQuery = `relations[]=content&select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0${treatmentCondition}${countryConditon}`;
        setTreatmentCondition(`&where[content][specialityId]=${specility}`)
        dispatch(treatmentActions.getAllRelated({ query: treatmentQueryDropdown }));
        dispatch(treatmentActions.getAll({ query: treatmentQuery }));

      }
      if(specility && treatment){
        let diseaseQueryDropdown = `relations[]=content&select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0${diseaseCondition}${countryConditon}`;
        let diseaseQuery = `relations[]=content&select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0${diseaseCondition}${countryConditon}`;
        dispatch(diseaseActions.getAllRelated({ query: diseaseQueryDropdown }));
        dispatch(diseaseActions.getAll({ query: diseaseQuery }));

      }
    },
    // eslint-disable-next-line
    [specility,treatment,drawer]
  );




  const countryOptions = countryRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const specialitiesOptions = specialityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const treatmentOptions = relationTreatmentRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const diseaseOptions = relationDiseaseRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  const handleSearch = () => {

    if(!specility){
      notification.warn({ description: "Please select specility." });
    }
    setSearchBtn(true);
  };


  const resetCountry = () => {
    setCountry(null);
    setCountryName('');
    setCountryConditon('')
    setSearchBtn(false);

  };

  const resetDisease = () => {
    setDisease(null);
    setDiseaseCondition('');
    setSearchBtn(false);
  };
  
  const resetTreatment = () => {
    setTreatment(null);
    setTreatmentCondition('');
    setTeatmentName('')
    resetDisease()

  };
 
  const resetSpecility = () => {
    setSpecility(null);
    resetTreatment()

  };

  const columns = [
    {
      title: (
        <>
          Name<span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (text_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text_en, entity) => {
        // Construct the URL based on the given conditions
        let url = "";
       
          url = `${process.env.REACT_APP_BASE_URL_USER}en/clinics/${countryName ? countryName.toLowerCase() + '/' : '' }${specilityName ? specilityName.toLowerCase()?.replace(/\s*\/\s*/g, "-")?.replace(/ /g, "-")?.replace(/-&-/g, "-") +'/' :''}${treatmentName ? treatmentName.toLowerCase()?.replace(/\s*\/\s*/g, "-")?.replace(/ /g, "-")?.replace(/-&-/g, "-") +'/' :''}${entity.name_en?.toLowerCase()?.replace(/\s*\/\s*/g, "-")?.replace(/ /g, "-")?.replace(/-&-/g, "-")}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              
              {(user.role === "admin" ||
                user.role === "author" ||
                user.role === "editor") && (
                <span className="nav-link mx-1">
                  <EditButton
                    onClick={() => {
                      setEditRecord(entity);
                      setDrawer("Country");
                    }}
                  />
                </span>
              )}
              {user.role === "admin" && (
                <span className="nav-link ">
                  <DeleteButton onClick={() => setDeleteRecord(entity)} />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };


  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
       
        <div className="row  " style={{ paddingRight: "50px" }}>
        <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {searchBtn ? (disease?diseaseRecords.length:treatment?diseaseRecords.length:specility?treatmentRecords.length:0) : 0}</h3>
            </div>
          </div>
          <div className="col-12 col-md-auto mx-3">
            <span className="drawer_span">Specilities</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="Specilities"
                value={
                  specialitiesOptions?.find(
                    (option) => option?.value === specility
                  ) || null
                }
                options={specialitiesOptions}
                onChange={(selectedOption) => {
                  setSearchBtn(false);
                  setSpecility(selectedOption ? selectedOption.value : null);
                  setSpecilityName(selectedOption ? selectedOption.label : null)
                  setTreatmentCondition(`&where[content][specialityId]=${selectedOption ? selectedOption.value : null}`)
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetSpecility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Treatments</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="tratments"
                value={
                  treatmentOptions?.find(
                    (option) => option?.value === treatment
                  ) || null
                }
                options={specility && treatmentOptions}
                onChange={(selectedOption) => {
                  setSearchBtn(false);
                  setTreatment(selectedOption ? selectedOption.value : null);
                  setTeatmentName(selectedOption ? selectedOption.label : null);
                  setDiseaseCondition(`where[content][treatmentId]=${selectedOption ? selectedOption.value : null}&where[content][specialityId]=${specility}`)

                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetTreatment}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Disease</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="diseases"
                value={
                  diseaseOptions?.find((option) => option?.value === disease) ||
                  null
                }
                options={treatment && diseaseOptions}
                onChange={(selectedOption) => {
                  setSearchBtn(false);
                  setDisease(selectedOption ? selectedOption.value : null);
                  setDiseaseCondition(`where[content][treatmentId]=${treatment}&where[content][specialityId]=${specility}&where[id]=${selectedOption ? selectedOption.value : null}`)

                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetDisease}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Country</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="countries"
                value={
                  countryOptions?.find((option) => option?.value === country) ||
                  null
                }
                options={countryOptions}
                onChange={(selectedOption) => {
                  setSearchBtn(false);
                  setCountry(selectedOption ? selectedOption.value : null);
                  setCountryName(selectedOption ? selectedOption.label : null);
                  setCountryConditon(`&where[content][countryId]=${selectedOption ? selectedOption.value : null}`);


                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetCountry}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          {/*  */}
          <div className="col-12 col-md-auto filter-search-btn">
          
              <button onClick={() => handleSearch()} className="btn btn-theme">  Search </button>

           
            </div>
          {/*  */}
        </div>
        {(
        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={searchBtn && (loadingdiseaseRecords?.getAll || loadingtreatmentRecords?.getAll || false)}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: "calc(100vh - 420px)" }}
            pagination={false}
            dataSource={searchBtn && (disease?diseaseRecords:treatment?diseaseRecords:specility?treatmentRecords:null)}

            columns={columns}
          />
       
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={disease?relationCountDisease:treatment?relationCountDisease:specility?relationCountTreatment.length:0}
            />
          </div>
          
        </div>
      )}
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default FilterContentTable;
