import { Modal, Upload } from "antd";
import FacilityDepartmentDrawer from "app/dashboard/common/facility-department/facility-department.drawer";
import FacilitySpecialityDrawer from "app/dashboard/facility/components/facility-speciality/facility-speciality.drawer";
import ServiceLanguageDrawer from "app/dashboard/common/service-language/service-language.drawer";
import about_Double_Bed_icon from "assets/images/about_Double_Bed_icon.svg";
import about_Surgeon_Skin_Type_icon from "assets/images/about_Surgeon_Skin_Type_icon.svg";
import about_departments_icon from "assets/images/about_departments_icon.svg";
import about_doctor_icon from "assets/images/about_doctor_icon.svg";
import about_year_of_foundation_icon from "assets/images/about_year_of_foundation_icon.svg";
import { UploadButton } from "components/button/Buttons";
import ErrorMessage from "components/error-message/ErrorMessage";
import GoogleMapComponent from "components/google-map/GoogleMapComponent";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import {
  createQueryParams,
  getBase64,
  sanitizeString,
} from "utils/helpers/helper-functions";
import { LanguageSegment } from "components/language-segment/LanguageSegment";

function BasicInfo({ formik, view }) {
  const {
    country,
    city,
    serviceLanguage,
  } = useSelector(
    (state) => ({
      country: state.country?.records,
      city: state.city?.records,
      serviceLanguage: state.serviceLanguage?.records,
    }),
    shallowEqual
  );

  const { Segment, language } = LanguageSegment();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [serviceLanguageDrawer, setServiceLanguageDrawer] = useState(false);
  const [facilityDepartmentDrawer, setFacilityDepartmentDrawer] = useState(false);
  const [facilitySpecialityDrawer, setFacilitySpecialityDrawer] = useState(false);

  const getserviceLanguagePagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const getfacilityDepartmentPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = ["speciality"];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const getfacilitySpecialityPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = ["media"];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const serviceLanguages = serviceLanguage?.map(
    ({ id: value, name_en }, index) => ({
      value,
      index,
      label: name_en,
    })
  );
  return (
    <>
      <ServiceLanguageDrawer
        setDrawer={setServiceLanguageDrawer}
        drawer={serviceLanguageDrawer}
        getPagination={getserviceLanguagePagination}
      />

      <FacilityDepartmentDrawer
        setDrawer={setFacilityDepartmentDrawer}
        drawer={facilityDepartmentDrawer}
        getPagination={getfacilityDepartmentPagination}
      />

      <FacilitySpecialityDrawer
        setDrawer={setFacilitySpecialityDrawer}
        drawer={facilitySpecialityDrawer}
        getPagination={getfacilitySpecialityPagination}        
      />

      <div className="language-segmented my-2 drawer_area_main_div mx-3 px-4">
        <div className=" my-auto mx-2">
         
        </div>
        {Segment}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="content container-fluid mt-2 mx-0 px-3">
          <div className="row">
            <div className="col-lg-7">
              {/****** Basic Info *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Basic Info</h5>
                </div>
                <div className="card-body">
                  {language === "en" && (
                    <>
                      {" "}
                      <span className="drawer_span required">
                        Name{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <div>
                        <input
                          disabled={view}
                          type="text"
                          placeholder=" Name in English"
                          name="name_en"
                          className={`form-control drawer_input ${
                            formik.errors.name_en && formik.touched.name_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_en}
                        />
                        {formik.errors.name_en && formik.touched.name_en && (
                          <ErrorMessage message={formik.errors.name_en} />
                        )}
                      </div>{" "}
                    </>
                  )}

                  {/************************/}

                  {language === "ar" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder=" Name in Arabic"
                        name="name_ar"
                        className={`my-2 form-control drawer_input ${
                          formik.errors.name_ar && formik.touched.name_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_ar}
                      />
                      {formik.errors.name_ar && formik.touched.name_ar && (
                        <ErrorMessage message={formik.errors.name_ar} />
                      )}{" "}
                    </>
                  )}

                  {/************************/}

                  {language === "ru" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="Name in Russian"
                        name="name_ru"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.name_ru && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_ru}
                      />
                      {formik.errors.name_ru && formik.touched.name_ru && (
                        <ErrorMessage message={formik.errors.name_ru} />
                      )}
                    </>
                  )}

                  {/************************/}

                  {language === "de" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="First Name in German"
                        name="name_de"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.name_de && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_de}
                      />
                      {formik.errors.name_de && formik.touched.name_de && (
                        <ErrorMessage message={formik.errors.name_de} />
                      )}{" "}
                    </>
                  )}

                   {/************************/}

                   {language === "uk" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Ukrainian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="First Name in Ukrainian"
                        name="name_uk"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.name_uk && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_uk}
                      />
                      {formik.errors.name_uk && formik.touched.name_uk && (
                        <ErrorMessage message={formik.errors.name_uk} />
                      )}{" "}
                    </>
                  )}

                  {/************************/}
                  <span className="drawer_span">Email </span>

                  <input
                    disabled={view}
                    type="text"
                    placeholder="Email"
                    name="email"
                    className={`form-control drawer_input ${
                      formik.errors.email && formik.touched.email
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMessage message={formik.errors.email} />
                  )}

                  {/************************/}
                  <span className="drawer_span">Phone </span>
                  <div>
                    <input
                      disabled={view}
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      className={`form-control drawer_input ${
                        formik.errors.phone && formik.touched.phone
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <ErrorMessage message={formik.errors.phone} />
                    )}
                  </div>
                  {/************************/}
                  <div className="row   mt-2">
                    <div className="col">
                      <span className="drawer_span required">Facility Type</span>
                      <Select
                        isDisabled={view}
                        name="type"
                        value={formik.values.type}
                        options={[
                          { value: "clinic", index: 0, label: "Clinic" },
                          { value: "hospital", index: 1, label: "Hospital" },
                        ]}
                        onChange={(e) => {
                          formik.setFieldValue("type", e);
                        }}
                        className={`drawer_input form-control w-100 basic-multi-select multiSelectBox ${
                          formik.errors.type && formik.touched.type
                            ? "input_error"
                            : ""
                        }`}
                        classNamePrefix="select"
                      />
                    </div>
                  </div>

                  {formik.errors.type && formik.touched.type && (
                    <ErrorMessage message={formik.errors.type} />
                  )}

                  {/*************Service Language ***********/}
                  <div className="row   mt-2">
                    <div className="col">
                      <span className="drawer_span">Service Language</span>
                      <div className="d-flex">
                        <Select
                          isDisabled={view}
                          isMulti
                          name="serviceLanguages"
                          value={formik.values.serviceLanguageIds}
                          options={serviceLanguages}
                          onChange={(e) => {
                            formik.setFieldValue("serviceLanguageIds", e);
                          }}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                        />
                        <div
                          className="col-sm-auto d-flex align-items-center  "
                          style={{ marginLeft: "0.6rem" }}
                        >
                          <span
                            onClick={() =>
                              setServiceLanguageDrawer(!serviceLanguageDrawer)
                            }
                            className=" btn btn-primary btn-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#newProjectModal"
                          >
                            <i className="bi-plus me-1"></i> Add
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*************Service Language ***********/}
                </div>
              </div>
              {/****** Images *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Images</h5>
                </div>

                <div className="card-body">
                  <div
                    id="attachFilesNewProjectLabel"
                    className="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                  >
                    <div className="dz-message">
                      <div className="d-flex align-items-center   h-100 text-center">
                        <Upload
                          disabled={view}
                          listType="picture-card"
                          fileList={formik.values.mediaUrls}
                          onPreview={handlePreview}
                          onChange={({ fileList: images }) => {
                            formik.setFieldValue("mediaUrls", images);
                          }}
                        >
                          {formik.values?.mediaUrls?.length >= 10 ? null : (
                            <UploadButton />
                          )}
                        </Upload>
                      </div>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewOpen(false)}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Overview *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Overview</h5>
                </div>

                <div className="card-body">
                  <div className=" ">
                    {/* Overview  */}

                    {language === "en" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span required">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (English)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            className="input_error"
                            value={formik.values.overview_en}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_en", value)
                            }
                          />
                          {formik.errors.overview_en &&
                            formik.touched.overview_en && (
                              <ErrorMessage
                                message={formik.errors.overview_en}
                              />
                            )}
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "ar" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Arabic)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_ar}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_ar", value)
                            }
                          />
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "de" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (German)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_de}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_de", value)
                            }
                          />
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "ru" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Russian)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_ru}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_ru", value)
                            }
                          />
                        </div>
                      </>
                    )}

                      {/* Overview  */}
                      {language === "uk" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Ukrainian)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_uk}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_uk", value)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/****** Achievement *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Achievement</h5>
                </div>

                <div className="card-body">
                  <div
                    id="attachFilesNewProjectLabel"
                    className="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                  >
                    <div className="dz-message">
                      <div className="d-flex align-items-center   h-100 text-center">
                        <Upload
                          disabled={view}
                          listType="picture-card"
                          fileList={formik.values.achievements}
                          onPreview={handlePreview}
                          onChange={({ fileList: images }) => {
                            formik.setFieldValue("achievements", images);
                          }}
                        >
                          {formik.values?.achievements?.length >= 10 ? null : (
                            <UploadButton />
                          )}
                        </Upload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {/****** Status *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Status</h5>
                </div>

                <div className="card-body container-fluid">
                  <div className="row">
                    <div className="col-6  ">
                      <div className="form-check form-switch">
                        <input
                          disabled={view}
                          name="isActive"
                          type="checkbox"
                          role="switch"
                          checked={formik.values.isActive}
                          className={`form-check-input drawer_input ${
                            formik.errors.isActive && formik.touched.isActive
                              ? "input_error"
                              : ""
                          }`}
                          onChange={(e) => {
                            formik.setFieldValue("isActive", e.target.checked);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.isActive}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Is Active?
                        </label>
                      </div>
                      {formik.errors.isActive && formik.touched.isActive && (
                        <ErrorMessage message={formik.errors.isActive} />
                      )}
                    </div>
                    <div className="col-6   ">
                      <div className="form-check form-switch">
                        <input
                          disabled={view}
                          name="isFeatured"
                          type="checkbox"
                          role="switch"
                          checked={formik.values.isFeatured}
                          className={` drawer_input form-check-input drawer_input ${
                            formik.errors.isFeatured &&
                            formik.touched.isFeatured
                              ? "input_error"
                              : ""
                          }`}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "isFeatured",
                              e.target.checked
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.isFeatured}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Is Featured?
                        </label>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/****** Address *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Address</h5>
                </div>

                <div className="card-body">
                  <div className="col">
                    {language === "en" && (
                      <>
                        {" "}
                        <span className="drawer_span required">
                          Address{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in English"
                          name="address_en"
                          className={`form-control drawer_input ${
                            formik.errors.address_en &&
                            formik.touched.address_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_en}
                        />
                        {formik.errors.address_en &&
                          formik.touched.address_en && (
                            <ErrorMessage message={formik.errors.address_en} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "ar" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(Arabic)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Arabic"
                          name="address_ar"
                          className={`my-2 form-control drawer_input ${
                            formik.errors.address_ar &&
                            formik.touched.address_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_ar}
                        />
                        {formik.errors.address_ar &&
                          formik.touched.address_ar && (
                            <ErrorMessage message={formik.errors.address_ar} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "ru" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Russian"
                          name="address_ru"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_ru &&
                            formik.touched.address_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_ru}
                        />
                        {formik.errors.address_ru &&
                          formik.touched.address_ru && (
                            <ErrorMessage message={formik.errors.address_ru} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "de" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(German)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in German"
                          name="address_de"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_de &&
                            formik.touched.address_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_de}
                        />
                        {formik.errors.address_de &&
                          formik.touched.address_de && (
                            <ErrorMessage message={formik.errors.address_de} />
                          )}
                      </>
                    )}

                     {/************************/}
                     {language === "uk" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(Ukrainian)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Ukrainian"
                          name="address_uk"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_uk &&
                            formik.touched.address_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_uk}
                        />
                        {formik.errors.address_uk &&
                          formik.touched.address_uk && (
                            <ErrorMessage message={formik.errors.address_uk} />
                          )}
                      </>
                    )}

                    <span className="drawer_span">Zip Code</span>
                    <input
                      disabled={view}
                      type="text"
                      placeholder="Zip Code"
                      name="zipCode"
                      className={`form-control drawer_input ${
                        formik.errors.zipCode && formik.touched.zipCode
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                    />
                    {formik.errors.zipCode && formik.touched.zipCode && (
                      <ErrorMessage message={formik.errors.zipCode} />
                    )}
                  </div>
                  <div className="col ">
                    <span className="drawer_span required">Country</span>
                    <select
                      disabled={view}
                      className="form-control drawer_input "
                      name="countryId"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("cityId", "");
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.countryId}
                    >
                      <option value={null}> Select Country</option>
                      {country?.map((entity, index) => (
                        <option value={entity.id} key={index}>
                          {entity.name_en}
                        </option>
                      ))}
                    </select>
                    {formik.errors.countryId && formik.touched.countryId && (
                      <ErrorMessage message={formik.errors.countryId} />
                    )}
                  </div>
                  <div className="col  ">
                    <span className="drawer_span required">City</span>
                    <select
                      disabled={view}
                      defaultValue={formik.values.countryId}
                      className={`form-control drawer_input ${
                        formik.errors.cityId && formik.touched.cityId
                          ? "input_error"
                          : ""
                      }`}
                      name="cityId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cityId}
                    >
                      <option value={""}> Select City</option>
                      {city?.map(
                        (entity, index) =>
                          formik.values.countryId === entity.countryId && (
                            <option value={entity.id} key={index}>
                              {entity.name_en}
                            </option>
                          )
                      )}
                    </select>
                    {formik.errors.cityId && formik.touched.cityId && (
                      <ErrorMessage message={formik.errors.cityId} />
                    )}
                  </div>
                </div>
              </div>
              {/****** Location *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Location</h5>
                </div>

                <div className="card-body">
                  <div className="pb-4">
                    <GoogleMapComponent
                      disabled={view}
                      onChange={(e) => {
                        formik.setFieldValue("lat", sanitizeString(e?.lat));
                        formik.setFieldValue("long", sanitizeString(e?.lng));
                      }}
                      location={{
                        lat: parseFloat(formik.values.lat) || 0,
                        lng: parseFloat(formik.values.long) || 0,
                      }}
                    />
                  </div>
                </div>
              </div>
              {/****** About *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>About</h5>
                </div>

                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={about_year_of_foundation_icon} alt="Flag" />
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="d-block">
                                Year of Foundation
                              </span>
                            </div>

                            <div className="col-auto">
                              <input
                                disabled={view}
                                placeholder="Year of Foundation"
                                name="foundation"
                                className={`form-control drawer_input  ${
                                  formik.errors.foundation &&
                                  formik.touched.foundation
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.foundation}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={about_departments_icon} alt="Flag" />
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="d-block">Department</span>
                            </div>

                            <div className="col-auto">
                              <input
                                disabled={view}
                                placeholder="Department"
                                name="department"
                                className={`form-control drawer_input ${
                                  formik.errors.department &&
                                  formik.touched.department
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.department}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={about_doctor_icon} alt="Flag" />
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="d-block">Doctors</span>
                            </div>

                            <div className="col-auto">
                              <input
                                disabled={view}
                                placeholder="Doctors"
                                name="doctor"
                                className={`form-control drawer_input ${
                                  formik.errors.doctor && formik.touched.doctor
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.doctor}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={about_Double_Bed_icon} alt="Flag" />
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="d-block">Beds</span>
                            </div>

                            <div className="col-auto">
                              <input
                                disabled={view}
                                placeholder="Beds"
                                name="bed"
                                className={`form-control drawer_input ${
                                  formik.errors.bed && formik.touched.bed
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bed}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img src={about_Surgeon_Skin_Type_icon} alt="Flag" />
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <span className="d-block">Yearly Patients</span>
                            </div>

                            <div className="col-auto">
                              <input
                                disabled={view}
                                placeholder="Yearly Patients"
                                name="yearly_patient"
                                className={`form-control drawer_input ${
                                  formik.errors.yearly_patient &&
                                  formik.touched.yearly_patient
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.yearly_patient}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default BasicInfo;
