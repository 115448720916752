import { Segmented } from "antd";
import React, { useState } from "react";

export const LanguageSegment = () => {
  const [language, setLanguage] = useState("en");
  const languages = [
    { label: <span className="required">English </span>, value: "en" },
    { label: "Arabic", value: "ar" },
    { label: "German", value: "de" },
    { label: "Russian", value: "ru" },
    { label: "Ukrainian", value: "uk" },

  ];

  const Segment = (
    <Segmented options={languages} value={language} onChange={setLanguage} />
  );

  return { Segment, language,languages };
};
