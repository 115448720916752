import { Tabs } from "antd";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import * as CountryAction from "redux/country/Actions";
import * as facilityActions from "redux/facility/Actions";
import * as doctorActions from "redux/doctor/Actions";
import Select from "react-select";
import TableViewCountry from "./tabs-view/country-tab-view";
import DoctorTabView from "./tabs-view/doctor-tab-view";
import FacilityTabView from "./tabs-view/facility-tab-view";
import SpecilitiesTabView from "./tabs-view/specilities-tab-view";

function AddContentTable(props) {
  const {
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    getPagination,
    setWhereText,
    // getAllContent
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  // const paginationContent = getAllContent()
  const {
    user,
    loading,
    countryRecords,
    countryRecordsCount,
    facilityRecords,
    facilityRecordsCount,
    doctorRecords,
    doctorRecordsCount,
    specialityRecords,
    specialityRecordsCount,
    relationCountCountry,
    relationCountDoctor,
    relationCountFacility,
    relationCountSpecility,
  } = useSelector(
    (state) => ({
      user: state.auth.user || null,
      count: state.content?.count || 0,
      loading: state.content?.loading,
      countryRecords: state.country?.records || [],
      countryRecordsCount: state.country?.count || [],

      facilityRecords: state.facility?.records || [],
      facilityRecordsCount: state.facility?.count || [],
      doctorRecords: state.doctor?.records || [],
      doctorRecordsCount: state.doctor?.count || [],
      specialityRecords: state.speciality?.records,
      specialityRecordsCount: state.speciality?.count,

      relationCountCountry: state.country.relationCount || 0,
      relationCountDoctor: state.doctor?.relationCount || 0,
      relationCountFacility: state.facility?.relationCount || 0,
      relationCountSpecility: state.speciality?.relationCount || 0,
    }),
    shallowEqual
  );

  const [country, setCountry] = useState("");
  const [facility, setFacility] = useState("");
  const [doctor, setDoctor] = useState("");
  const [specility, setSpecility] = useState("");
  const [countryConditon, setCountryConditon] = useState("");
  const [doctorCondition, setDoctorCondition] = useState("");
  const [specilityCondition, setSpecilityCondition] = useState("");
  const [selectedTab, setSelectedTab] = useState("1");

  useEffect(
    () => {
      let countryQuery = `select[name_en]=true&select[id]=true&limit=1000000&offset=0`;
      let facilityQuery = `select[name_en]=true&select[id]=true&limit=1000000&offset=0${countryConditon}`;
      let doctorQuery = `select[firstName_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0${doctorCondition}`;
      let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&${specilityCondition}`;

      dispatch(CountryAction.getAll({ query: countryQuery }));
      dispatch(facilityActions.getAll({ query: facilityQuery }));
      dispatch(doctorActions.getAll({ query: doctorQuery }));
      dispatch(specialitiesAction.getAll({ query: specilityQuery }));
    },
    // eslint-disable-next-line
    [
      countryConditon,
      doctorCondition,
      specilityCondition,
    ]
  );

  // Country tab table records
  let queryCountry = `select[name_en]=trueselect[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0`;
  if (country) {
    queryCountry += `&where[id]=${country}`;
  }
  // Country tab table records
  // Hospital  tab table records

  let queryHospital = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0`;
  if (country) {
    queryHospital += `&where[content][countryId]=${country}`;
  }
  if (facility) {
    queryHospital += `&where[id]=${facility}`;
  }
  // Hospital  tab table records
  // Doctor  tab table records

  let queryDoctor = `select[firstName_en]=true&select[lastName_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0`;
  if (country) {
    queryDoctor += `&where[content][countryId]=${country}`;
  }
  if (facility) {
    queryDoctor += `&where[content][facilityId]=${facility}`;
  }

  // Doctor  tab table records
  // Specility  tab table records
  let querySpecility = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0`;
  if (country) {
    querySpecility += `&where[content][countryId]=${country}`;
  }
  if (facility) {
    querySpecility += `&where[content][facilityId]=${facility}`;
  }
  if (doctor) {
    querySpecility += `&where[content][doctorId]=${doctor}`;
  }

  // Specility  tab table records

  const fetchTabsContent = useCallback(
    () => {
      dispatch(CountryAction.getAllRelated({ query: queryCountry }));
      dispatch(doctorActions.getAllRelated({ query: queryDoctor }));
      dispatch(facilityActions.getAllRelated({ query: queryHospital }));
      dispatch(specialitiesAction.getAllRelated({ query: querySpecility }));
    },
    // eslint-disable-next-line
    [country, facility, doctor, specility]
  );

  useEffect(
    () => {
      fetchTabsContent();
    },
    // eslint-disable-next-line
    [country, facility, doctor, specility]
  );

  const countryOptions = countryRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const facilityOptions = facilityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const doctorOptions = doctorRecords?.map(
    ({ id: value, firstName_en }, index) => ({
      value,
      label: firstName_en,
      index,
    })
  );
  const specialitiesOptions = specialityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  useEffect(() => {
    let queryStringParts = [];

    if (country) {
      queryStringParts.push(`where[countryId]=${country}`);
      setCountryConditon(`&where[countryId]=${country}`);
    }

    // if (facility || country) {
    //   queryStringParts.push(`where[facilityId]=${facility}`);
      
    //   !facility && country && setDoctorCondition(
    //     `&where[countryId]=${country}`
    //   );
    //   facility && !country && setDoctorCondition(
    //     `&where[facilityDoctor][facilityId]=${facility}`
    //   );

    //   facility && country && setDoctorCondition(
    //     `&where[countryId]=${country}&where[facilityDoctor][facilityId]=${facility}`
    //   );
    //   setSpecilityCondition(
    //     `&where[facilitySpeciality][facilityId]=${facility}`
    //   );
    // }
    if (facility || country) {
      // Add facilityId to query if facility exists
      facility && queryStringParts.push(`where[facilityId]=${facility}`);
    
      // Handle the doctor conditions based on facility and country presence
      if (!facility && country) {
        setDoctorCondition(`&where[countryId]=${country}`);
      } else if (facility && !country) {
        setDoctorCondition(`&where[facilityDoctor][facilityId]=${facility}`);
      } else if (facility && country) {
        setDoctorCondition(`&where[countryId]=${country}&where[facilityDoctor][facilityId]=${facility}`);
      }
    
      // Set specialty condition if facility exists
      facility && setSpecilityCondition(`&where[facilitySpeciality][facilityId]=${facility}`);
    }
    

    if (doctor) {
      queryStringParts.push(`where[doctorId]=${doctor}`);
    }
    if (specility) {
      queryStringParts.push(`where[specialityId]=${specility}`);
    }

    // Combine all parts into a single query string
    const newQueryString =
      queryStringParts.length > 0 ? queryStringParts.join("&") : null;
    setWhereText(newQueryString ? newQueryString : null);
  }, [country, facility, doctor, specility, setWhereText]);

  const resetCountry = () => {
    setCountry(null);
    setCountryConditon('');
    setDoctorCondition('');
  };
  const resetFacility = () => {
    setFacility(null);
    setDoctorCondition('');
    setSpecilityCondition('');
  };
  const resetDoctor = () => {
    setDoctor(null);
    setDoctorCondition('');
  };
  const resetSpecility = () => {
    setSpecility(null);
  };

  const items = [
    {
      key: "1",
      label: `Country (${relationCountCountry})`,
      disabled: false,
      children: (
        <TableViewCountry
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            pageSize,
            setPageSize,
            pageNumber,
            setPageNumber,
            country,
          }}
        />
      ),
    },
    {
      key: "2",
      label: `Hospitals/Clinics (${relationCountFacility})`,
      children: (
        <FacilityTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            pageSize,
            setPageSize,
            pageNumber,
            setPageNumber,
            country,
            facility,
            selectedTab,
          }}
        />
      ),
    },
    {
      key: "3",
      label: `Doctor (${relationCountDoctor})`,
      children: (
        <DoctorTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            pageSize,
            setPageSize,
            pageNumber,
            setPageNumber,
            country,
            facility,
          }}
        />
      ),
    },
    {
      key: "4",
      label: `Specilities (${relationCountSpecility})`,
      children: (
        <SpecilitiesTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            pageSize,
            setPageSize,
            pageNumber,
            setPageNumber,
            country,
            facility,
            doctor,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row mx-2 " style={{ paddingRight: "50px" }}>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Country</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="countries"
                value={
                  countryOptions?.find((option) => option?.value === country) ||
                  null
                }
                options={countryOptions}
                onChange={(selectedOption) => {
                  setCountry(selectedOption ? selectedOption.value : null);
                  // setCity(null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetCountry}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Hospitals/Clinics</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="facilities"
                value={
                  facilityOptions?.find(
                    (option) => option?.value === facility
                  ) || null
                }
                options={facilityOptions}
                onChange={(selectedOption) => {
                  setFacility(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetFacility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Doctor</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="doctors"
                value={
                  doctorOptions?.find((option) => option?.value === doctor) ||
                  null
                }
                options={doctorOptions}
                onChange={(selectedOption) => {
                  setDoctor(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetDoctor}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Specilities</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="Specilities"
                value={
                  specialitiesOptions?.find(
                    (option) => option?.value === specility
                  ) || null
                }
                options={specialitiesOptions}
                onChange={(selectedOption) => {
                  setSpecility(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetSpecility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
        </div>

        <div className="bg-white mx-3 mt-5">
          <Tabs
            defaultActiveKey="1"
            items={items}
            activeKey={selectedTab}
            onChange={(key) => setSelectedTab(key)}
          />
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default AddContentTable;
