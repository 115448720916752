import React from "react";
import "./dashboard-home.style.css";
import DashboardChart from "./dashboard.chart";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/statistics/Actions";
import DashboardCards from "./dashboard.card";

const DashboardHome = () => {
  const dispatch = useDispatch();

  const { statistics, loading } = useSelector(
    (state) => ({
      statistics: state.statistics.statistics || {},
      loading: state.speciality?.loading,
    }),
    shallowEqual
  );

  const fetchStatistics = useCallback(() => {
    dispatch(actions.getAll());
  }, [dispatch]);

  useEffect(() => {
    fetchStatistics();
  }, 
   // eslint-disable-next-line
  []);

  return (
    <>
      <div className="py-4 mt-3 mx-5 table_main_div">
        <h1 className="text-center my-1 ">Dashboard</h1>
      </div>

      <div className="py-4 mt-3 mx-5 table_main_div">
        <DashboardChart statistics={statistics} loading={loading?.getAll} />
      </div>

      <DashboardCards statistics={statistics} loading={loading?.getAll} />
    </>
  );
};

export default DashboardHome;
