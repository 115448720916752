import { Drawer, Image, Space, Spin, Upload, notification } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/team/Actions";
import * as uploadActions from "redux/upload/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";

function TeamsDrawer(props) {
  const { edit, setEdit, view, setView, getPagination, drawer, setDrawer } =
    props;

  const record = edit || view;
  const { Segment, language } = LanguageSegment();
  

  const { loading,user } = useSelector(
    (state) => ({
      loading: state.country?.loading,
      user: state.auth?.user
    }),
    shallowEqual
  );
  const {
    firstName_en = "",
    firstName_ar = "",
    firstName_de = "",
    firstName_ru = "",
    firstName_uk = "",

    lastName_en = "",
    lastName_ar = "",
    lastName_de = "",
    lastName_ru = "",
    lastName_uk = "",

    hobbies_en = "",
    hobbies_ar = "",
    hobbies_de = "",
    hobbies_ru = "",
    hobbies_uk = "",

    designation_en = "",
    designation_ar = "",
    designation_de = "",
    designation_ru = "",
    designation_uk = "",
    email = null,
    image = null,
    id = null,
    isLeader=false,
    isFeatured = false,
    isActive=false
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = edit ? loading.update : loading.create;

  const title = `${view ? " View " : edit ? " Edit " : " Add "} Team Player`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(edit ? image : "");
  const [open, setOpen] = useState(false);


  const initialValues = {
    firstName_en: firstName_en || "",
    firstName_ar: firstName_ar || "",
    firstName_ru: firstName_ru || "",
    firstName_de: firstName_de || "",
    firstName_uk: firstName_uk || "",

    lastName_en: lastName_en || "",
    lastName_ar: lastName_ar || "",
    lastName_ru: lastName_ru || "",
    lastName_de: lastName_de || "",
    lastName_uk: lastName_uk || "",

    hobbies_en: hobbies_en || "",
    hobbies_ar: hobbies_ar || "",
    hobbies_ru: hobbies_ru || "",
    hobbies_de: hobbies_de || "",
    hobbies_uk: hobbies_uk || "",

    designation_en: designation_en || "",
    designation_ar: designation_ar || "",
    designation_ru: designation_ru || "",
    designation_de: designation_de || "",
    designation_uk: designation_uk || "",

    email: email || null,
    userId: user?.id || "",
    isLeader: isLeader || false,
    image: image || "",
    isFeatured: isFeatured || false,
    isActive: isActive || false,

  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    setPreviewImage(image || "");
  };

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewImage("");
    setImage(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEdit(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  const validationSchema = Yup.object().shape({
    firstName_en: Yup.string().required("First Name (English) is required"),
    firstName_ar: Yup.string().notRequired(),
    firstName_ru: Yup.string().notRequired(),
    firstName_de: Yup.string().notRequired(),
    firstName_uk: Yup.string().notRequired(),
    lastName_en: Yup.string().required("Last Name (English) is required"),
    lastName_ar: Yup.string().notRequired(),
    lastName_ru: Yup.string().notRequired(),
    lastName_de: Yup.string().notRequired(),
    lastName_uk: Yup.string().notRequired(),
    isFeatured: Yup.boolean().notRequired(),
    isActive: Yup.boolean().notRequired(),
    isLeader: Yup.boolean().notRequired(),
    email: Yup.string().nullable().notRequired(),
    hobbies_en: Yup.string().notRequired(),
    hobbies_ar: Yup.string().notRequired(),
    hobbies_de: Yup.string().notRequired(),
    hobbies_ru: Yup.string().notRequired(),
    hobbies_uk: Yup.string().notRequired(),
    designation_en: Yup.string().required("Designation (English) is required"),
    designation_ar: Yup.string().notRequired(),
    designation_de: Yup.string().notRequired(),
    designation_ru: Yup.string().notRequired(),
    designation_uk: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const onSubmit = async (values) => {
    setOpen(false)
    dispatch(actions.loading(edit ? { update: true } : { create: true }));

    if (uploadedImage) {
      const Images = await dispatch(uploadActions.upload([uploadedImage]));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        dispatch(actions.loading(edit ? { update: false } : { create: false }));
        return;
      }
      values.image = Images[0] || "";
    }
    if (values.email === "") {
      values.email = null;
    }

    const action = edit
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!edit && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented   px-3  mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>

        <div className="drawer_main_div ">
          <h2 className=" text-center ">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                {language === "en" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span required">
                        First Name{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                      disabled={view}
                      type="text"
                      placeholder="First Name"
                      name="firstName_en"
                      className={`form-control drawer_input ${
                        formik.errors.firstName_en && formik.touched.firstName_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName_en}
                    />
                    {formik.errors.firstName_en && formik.touched.firstName_en && (
                      <ErrorMessage message={formik.errors.firstName_en} />
                    )}
                    </div>

                    <div className="my-2">
                      <span className="drawer_span required">
                        Last Name{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                      disabled={view}
                      type="text"
                      placeholder="Last Name"
                      name="lastName_en"
                      className={` form-control drawer_input ${
                        formik.errors.lastName_en && formik.touched.lastName_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName_en}
                    />
                    {formik.errors.lastName_en && formik.touched.lastName_en && (
                      <ErrorMessage message={formik.errors.lastName_en} />
                    )}
                    </div>
                    <div className="my-2">
                      <span className="drawer_span required">
                      Designation{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                      disabled={view}
                      type="text"
                      placeholder="Designation"
                      name="designation_en"
                      className={` form-control drawer_input ${
                        formik.errors.designation_en && formik.touched.designation_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.designation_en}
                    />
                    {formik.errors.designation_en && formik.touched.designation_en && (
                      <ErrorMessage message={formik.errors.designation_en} />
                    )}
                    </div>
                    <div className="my-2">
                      <span className="drawer_span">
                      Hobbies{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <input
                      disabled={view}
                      type="text"
                      placeholder="Hobbies , separated"
                      name="hobbies_en"
                      className={` form-control drawer_input ${
                        formik.errors.hobbies_en && formik.touched.hobbies_en
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.hobbies_en}
                    />
                    {formik.errors.hobbies_en && formik.touched.hobbies_en && (
                      <ErrorMessage message={formik.errors.hobbies_en} />
                    )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "ar" && (
                   <>
                   <div className="my-2">
                     <span className="drawer_span required">
                       First Name{" "}
                       <span className="form-label-secondary">(Arabic)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="First Name"
                     name="firstName_ar"
                     className={`form-control drawer_input ${
                       formik.errors.firstName_ar && formik.touched.firstName_ar
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.firstName_ar}
                   />
                   {formik.errors.firstName_ar && formik.touched.firstName_ar && (
                     <ErrorMessage message={formik.errors.firstName_ar} />
                   )}
                   </div>

                   <div className="my-2">
                     <span className="drawer_span required">
                       Last Name{" "}
                       <span className="form-label-secondary">(Arabic)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Last Name"
                     name="lastName_ar"
                     className={` form-control drawer_input ${
                       formik.errors.lastName_ar && formik.touched.lastName_ar
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.lastName_ar}
                   />
                   {formik.errors.lastName_ar && formik.touched.lastName_ar && (
                     <ErrorMessage message={formik.errors.lastName_ar} />
                   )}
                   </div>
                   <div className="my-2">
                     <span className="drawer_span">
                     Designation{" "}
                       <span className="form-label-secondary">(Arabic)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Designation"
                     name="designation_ar"
                     className={` form-control drawer_input ${
                       formik.errors.designation_ar && formik.touched.designation_ar
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.designation_ar}
                   />
                   {formik.errors.designation_ar && formik.touched.designation_ar && (
                     <ErrorMessage message={formik.errors.designation_ar} />
                   )}
                   </div>
                   <div className="my-2">
                     <span className="drawer_span">
                     Hobbies{" "}
                       <span className="form-label-secondary">(Arabic)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Hobbies , separated"
                     name="hobbies_ar"
                     className={` form-control drawer_input ${
                       formik.errors.hobbies_ar && formik.touched.hobbies_ar
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.hobbies_ar}
                   />
                   {formik.errors.hobbies_ar && formik.touched.hobbies_ar && (
                     <ErrorMessage message={formik.errors.hobbies_ar} />
                   )}
                   </div>
                 </>
                )}
                {/* /********** INPUT ********/}
                {language === "ru" && (
                 <>
                 <div className="my-2">
                   <span className="drawer_span required">
                     First Name{" "}
                     <span className="form-label-secondary">(Russian)</span>
                   </span>
                   <input
                   disabled={view}
                   type="text"
                   placeholder="First Name"
                   name="firstName_ru"
                   className={`form-control drawer_input ${
                     formik.errors.firstName_ru && formik.touched.firstName_ru
                       ? "input_error"
                       : ""
                   }`}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.firstName_ru}
                 />
                 {formik.errors.firstName_ru && formik.touched.firstName_ru && (
                   <ErrorMessage message={formik.errors.firstName_ru} />
                 )}
                 </div>

                 <div className="my-2">
                   <span className="drawer_span required">
                     Last Name{" "}
                     <span className="form-label-secondary">(Russian)</span>
                   </span>
                   <input
                   disabled={view}
                   type="text"
                   placeholder="Last Name"
                   name="lastName_ru"
                   className={` form-control drawer_input ${
                     formik.errors.lastName_ru && formik.touched.lastName_ru
                       ? "input_error"
                       : ""
                   }`}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.lastName_ru}
                 />
                 {formik.errors.lastName_ru && formik.touched.lastName_ru && (
                   <ErrorMessage message={formik.errors.lastName_ru} />
                 )}
                 </div>
                 <div className="my-2">
                   <span className="drawer_span">
                   Designation{" "}
                     <span className="form-label-secondary">(Russian)</span>
                   </span>
                   <input
                   disabled={view}
                   type="text"
                   placeholder="Designation"
                   name="designation_ru"
                   className={` form-control drawer_input ${
                     formik.errors.designation_ru && formik.touched.designation_ru
                       ? "input_error"
                       : ""
                   }`}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.designation_ru}
                 />
                 {formik.errors.designation_ru && formik.touched.designation_ru && (
                   <ErrorMessage message={formik.errors.designation_ru} />
                 )}
                 </div>
                 <div className="my-2">
                   <span className="drawer_span">
                   Hobbies{" "}
                     <span className="form-label-secondary">(Russian)</span>
                   </span>
                   <input
                   disabled={view}
                   type="text"
                   placeholder="Hobbies , separated"
                   name="hobbies_ru"
                   className={` form-control drawer_input ${
                     formik.errors.hobbies_ru && formik.touched.hobbies_ru
                       ? "input_error"
                       : ""
                   }`}
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.hobbies_ru}
                 />
                 {formik.errors.hobbies_ru && formik.touched.hobbies_ru && (
                   <ErrorMessage message={formik.errors.hobbies_ru} />
                 )}
                 </div>
               </>
                )}
                {/* /********** INPUT ********/}
                {language === "de" && (
                  <>
                  <div className="my-2">
                    <span className="drawer_span required">
                      First Name{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                    disabled={view}
                    type="text"
                    placeholder="First Name"
                    name="firstName_de"
                    className={`form-control drawer_input ${
                      formik.errors.firstName_de && formik.touched.firstName_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName_de}
                  />
                  {formik.errors.firstName_de && formik.touched.firstName_de && (
                    <ErrorMessage message={formik.errors.firstName_de} />
                  )}
                  </div>

                  <div className="my-2">
                    <span className="drawer_span required">
                      Last Name{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                    disabled={view}
                    type="text"
                    placeholder="Last Name"
                    name="lastName_de"
                    className={` form-control drawer_input ${
                      formik.errors.lastName_de && formik.touched.lastName_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName_de}
                  />
                  {formik.errors.lastName_de && formik.touched.lastName_de && (
                    <ErrorMessage message={formik.errors.lastName_de} />
                  )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span">
                    Designation{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                    disabled={view}
                    type="text"
                    placeholder="Designation"
                    name="designation_de"
                    className={` form-control drawer_input ${
                      formik.errors.designation_de && formik.touched.designation_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.designation_de}
                  />
                  {formik.errors.designation_de && formik.touched.designation_de && (
                    <ErrorMessage message={formik.errors.designation_de} />
                  )}
                  </div>
                  <div className="my-2">
                    <span className="drawer_span">
                    Hobbies{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                    disabled={view}
                    type="text"
                    placeholder="Hobbies , separated"
                    name="hobbies_de"
                    className={` form-control drawer_input ${
                      formik.errors.hobbies_de && formik.touched.hobbies_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.hobbies_de}
                  />
                  {formik.errors.hobbies_de && formik.touched.hobbies_de && (
                    <ErrorMessage message={formik.errors.hobbies_de} />
                  )}
                  </div>
                </>
                )}
                 {/* /********** INPUT ********/}
                 {language === "uk" && (
                   <>
                   <div className="my-2">
                     <span className="drawer_span required">
                       First Name{" "}
                       <span className="form-label-secondary">(Ukrainian)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="First Name"
                     name="firstName_uk"
                     className={`form-control drawer_input ${
                       formik.errors.firstName_uk && formik.touched.firstName_uk
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.firstName_uk}
                   />
                   {formik.errors.firstName_uk && formik.touched.firstName_uk && (
                     <ErrorMessage message={formik.errors.firstName_uk} />
                   )}
                   </div>

                   <div className="my-2">
                     <span className="drawer_span required">
                       Last Name{" "}
                       <span className="form-label-secondary">(Ukrainian)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Last Name"
                     name="lastName_uk"
                     className={` form-control drawer_input ${
                       formik.errors.lastName_uk && formik.touched.lastName_uk
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.lastName_uk}
                     value={formik.values.lastName_uk}
                   />
                   {formik.errors.lastName_uk && formik.touched.lastName_uk && (
                     <ErrorMessage message={formik.errors.lastName_uk} />
                   )}
                   </div>
                   <div className="my-2">
                     <span className="drawer_span">
                     Designation{" "}
                       <span className="form-label-secondary">(Ukrainian)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Designation"
                     name="designation_uk"
                     className={` form-control drawer_input ${
                       formik.errors.designation_uk && formik.touched.designation_uk
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.designation_uk}
                   />
                   {formik.errors.designation_uk && formik.touched.designation_uk && (
                     <ErrorMessage message={formik.errors.designation_uk} />
                   )}
                   </div>
                   <div className="my-2">
                     <span className="drawer_span">
                     Hobbies{" "}
                       <span className="form-label-secondary">(Ukrainian)</span>
                     </span>
                     <input
                     disabled={view}
                     type="text"
                     placeholder="Hobbies , separated"
                     name="hobbies_uk"
                     className={` form-control drawer_input ${
                       formik.errors.hobbies_uk && formik.touched.hobbies_uk
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.hobbies_uk}
                   />
                   {formik.errors.hobbies_uk && formik.touched.hobbies_uk && (
                     <ErrorMessage message={formik.errors.hobbies_uk} />
                   )}
                   </div>
                 </>
                )}
                 <div className="my-2">
                     <span className="drawer_span">
                     Email{" "}
                     </span>
                     <input
                     disabled={view}
                     type="email"
                     placeholder="Email"
                     name="email"
                     className={` form-control drawer_input ${
                       formik.errors.email && formik.touched.email
                         ? "input_error"
                         : ""
                     }`}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.email}
                   />
                   {formik.errors.email && formik.touched.email && (
                     <ErrorMessage message={formik.errors.email} />
                   )}
                   </div>
                {/* /********** Featured ********/}
                <div className="d-flex">
                <div className="col-md-4 col-sm-12 form-check form-switch my-2">
                  <input
                    disabled={view}
                    name="isLeader"
                    type="checkbox"
                    role="switch"
                    checked={formik.values.isLeader}
                    className={` drawer_input form-check-input drawer_input ${
                      formik.errors.isLeader && formik.touched.isLeader
                        ? "input_error"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("isLeader", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.isLeader}
                  />
                  <span className="">
                    Is Leader{" "}
                    
                  </span>
                  </div>
                  {formik.errors.isLeader && formik.touched.isLeader && (
                    <ErrorMessage message={formik.errors.isLeader} />
                  )}

                    {/* /********** INPUT ********/}
                    <div className="col-md-4 col-sm-12 form-check form-switch my-2">
                    <input
                      disabled={view}
                      name="isActive"
                      type="checkbox"
                      role="switch"
                      checked={formik.values.isActive}
                      className={` drawer_input form-check-input drawer_input ${
                        formik.errors.isActive && formik.touched.isActive
                          ? "input_error"
                          : ""
                      }`}
                      onChange={(e) => {
                        formik.setFieldValue("isActive", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.isActive}
                    />
                    <span className="drawer_span_no-margin">
                    Active?
                    </span>
                  </div>
                  {formik.errors.isActive && formik.touched.isActive && (
                    <ErrorMessage message={formik.errors.isActive} />
                  )}

                  <div className="col-md-4 col-sm-12 form-check form-switch my-2">
                  <input
                    disabled={view}
                    name="isFeatured"
                    type="checkbox"
                    role="switch"
                    checked={formik.values.isFeatured}
                    className={` drawer_input form-check-input drawer_input ${
                      formik.errors.isFeatured && formik.touched.isFeatured
                        ? "input_error"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("isFeatured", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.isFeatured}
                  />
                  <span className="">
                    Is Featured{" "}
                    <span className="form-label-secondary">
                      (Display on navbar?)
                    </span>
                  </span>
                  </div>
                  {formik.errors.isFeatured && formik.touched.isFeatured && (
                    <ErrorMessage message={formik.errors.isFeatured} />
                  )}
                  
                </div>

                
             
             
             
             
                
                {/* /********** Image ********/}
                <div className=" m-auto my-4 text-center ww-50">
                  <Image
                    className="drawer_input"
                    preview={previewImage ? true : false}
                    width={150}
                    height={150}
                    src={previewImage || FALLBACK_IMAGE_URL}
                    fallback={FALLBACK_IMAGE_URL}
                  />

                  <div className="mb-4 mt-2 text-center ">
                    {!view && (
                      <>
                        {" "}
                        <Upload
                          showUploadList={false}
                          maxCount={1}
                          disabled={isLoading || view}
                          onChange={onChange}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>
                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default TeamsDrawer;
