import { Navigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

//  used to validate if user is currently logged in or  not
export const IsLoginValidation = ({ children }) => {
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  if (!authState.firebaseToken || !authState.user) {
    return <Navigate to="/login" />;
  }
  return children;
};
