import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/settings/Actions";

function Settings() {
  const dispatch = useDispatch();
  const { records, 
    // loading 
  } = useSelector(
    (state) => (
      {
      records: state.settings.records || [],
      // loading: state.settings?.loading,
    }),
    shallowEqual
  );
  const fetchSettings = useCallback(() => {
    dispatch(actions.getAll());
  }, [dispatch]);

  useEffect(() => {
    fetchSettings();
  },
    // eslint-disable-next-line 
    [dispatch]);

    const handleToggleChange = (id, checked) => {
      dispatch(actions.update(id, checked));
    };
  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Settings</h1>
      </div>
      <div className="pb-2 pt-3 table_main_div height-100vh" >
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 my-4">
            <span className="mx-5">
              Auto Assign Requests
            </span>
            <div className="mx-4 my-1">
              <div className="button-toggles r" id="button-1">
                <input type="checkbox" className="checkbox"
                 checked={records.length > 0 ? records[0].autoAssignRequest : false} 
                 onChange={(e) => handleToggleChange(records.length > 0 ? records[0].id : false, records.length > 0 ? !records[0].autoAssignRequest : false)} // Pass id and checked state to handleCheckboxChange
                 />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;