import NoDataIcon from "components/animation-icons/no-data-icon";
import { SpecialitySelect } from "./common/specilities.select";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import FacilitySpecialityDrawer from "app/dashboard/facility/components/facility-speciality/facility-speciality.drawer";
import { useState, useEffect, useCallback } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import * as FacilitySpecialityActions from "redux/common/facility-speciality/Actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function FacilitySpecilities({ formik, view }) {
  const { Segment, language } = LanguageSegment();
  const [facilitySpecialityDrawer, setFacilitySpecialityDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [specialityValues, setSpecialityValues] = useState([]);
  const [matchedEntities, setMatchedEntities] = useState([]);
  const dispatch = useDispatch();
  const { entity } = useSelector(
    (state) => ({ entity: state.facilitySpeciality?.facilitySpecialitiesRecords }),
    shallowEqual
  );

  const handleEdit = (newRecord, id) => {
    newRecord.id = id;
    setEditRecord(newRecord);
    setFacilitySpecialityDrawer(false);
    formik.setFieldValue('specialityIds', formik.values.specialityIds.map(sep => {
      if (sep.value === newRecord.id) {
        return { ...sep, label: newRecord.name_en };
      }
      return sep;
    }));
  };

  const getUpdatedData = useCallback(() => {
    if (editRecord?.facilityId || formik?.values.id) {
      const facilitySpecialityQuery = `limit=1000000&offset=0&relations=speciality&where[facilityId]=${editRecord?.facilityId || formik?.values.id}`;
      dispatch(FacilitySpecialityActions.getAllFaciltiesSpecialities({ query: facilitySpecialityQuery }));
    }
  }, [dispatch, editRecord, formik?.values.id]);

  useEffect(() => {
    getUpdatedData();
  }, [facilitySpecialityDrawer, getUpdatedData]);

  useEffect(() => {
    const specialityValues = formik?.values?.specialityIds?.map((speciality) => speciality.value) || [];
    const filteredEntities = entity?.filter((e) => specialityValues.includes(e.specialityId)) || [];

    const sortedSpecialityIds = [...formik.values.specialityIds].sort((a, b) => {
      const indexA = filteredEntities.findIndex(entity => entity.specialityId === a.value);
      const indexB = filteredEntities.findIndex(entity => entity.specialityId === b.value);
      return indexA - indexB;
    });

    const sortedMatchedEntities = sortedSpecialityIds.map(speciality => {
      return filteredEntities.find(entity => entity.specialityId === speciality.value);
    });

    formik.setFieldValue("specialityIds", sortedSpecialityIds, false);
    setSpecialityValues(specialityValues);
    setMatchedEntities(sortedMatchedEntities);
  }, [facilitySpecialityDrawer, entity]);

  useEffect(() => {
    if (entity?.length === specialityValues?.length + 1) {
      const lastEntity = entity[entity.length - 1];
      if (!specialityValues.includes(lastEntity.specialityId)) {
        specialityValues.push(lastEntity.specialityId);
        matchedEntities.push(lastEntity);
        formik.values.specialityIds.push({
          "value": lastEntity?.specialityId,
          "label": lastEntity?.speciality.name_en,
          "index": entity.length
        });
        setSpecialityValues([...specialityValues]);
        setMatchedEntities([...matchedEntities]);
      }
    }
  }, [entity, specialityValues, matchedEntities, formik]);

  const getfacilitySpecialityPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const handleDeleteSpecility = (entityIndex,entity) => {
    dispatch(FacilitySpecialityActions.removeFacilitySpecility(entity.id));

    // Remove through Api and add in the select option of facilities
    const updatedSpecialityIds = formik?.values?.specialityIds.filter(
      (item, itemIndex) => entityIndex !== itemIndex
    );
    const updatedMatchedEntities = matchedEntities.filter(
      (item, itemIndex) => entityIndex !== itemIndex
    );
    formik.setFieldValue("specialityIds", updatedSpecialityIds, false);
    setMatchedEntities(updatedMatchedEntities);

  };
  return (
    <>
      <div className="language-segmented drawer_area_main_div mx-3 px-4">
        {Segment}
      </div>
      <div className="language-segmented drawer_area_main_div w-auto mx-3 px-4 mt-4">
        <div className="float-end" style={{ marginLeft: "0.6rem" }}>
          <span
            onClick={() => setFacilitySpecialityDrawer(!facilitySpecialityDrawer)}
            className="btn btn-primary btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#newProjectModal"
          >
            <i className="bi-plus me-1"></i> Add Specilities
          </span>
        </div>
      </div>
          <FacilitySpecialityDrawer
            setDrawer={setFacilitySpecialityDrawer}
            drawer={facilitySpecialityDrawer}
            getPagination={getfacilitySpecialityPagination}
            editRecord={editRecord}
            setEditRecord={setEditRecord}
            onEdit={handleEdit}
            idOfFacility={formik}
          />

      {matchedEntities?.length ? (
        <>
          <div className="content container-fluid my-3 px-3">
            <div className="row justify-content-lg-center">
              <div className="col-lg-12 ">
                <div className="tab-content" id="profileTeamsTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row row-cols-1">
                      {matchedEntities.map((entity, entityIndex) => (
                        <div className="col mb-3" key={entity?.specialityId && entity?.specialityI}>
                          <div className="card card-body">
                            <div className="row align-items-md-center">
                              <div className="col-3 col-md-auto order-md-last text-end">
                                <div className="dropdown">
                                  <button
                                    disabled={view}
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                    id="teamsListDropdown1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi-three-dots-vertical"></i>
                                  </button>

                                  <div
                                    className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                                    aria-labelledby="teamsListDropdown1"
                                  >
                                    <div
                                      className="dropdown-item text-danger"
                                      onClick={() => handleDeleteSpecility(entityIndex,entity)}
                                      // onClick={() => {
                                      //   const updatedSpecialityIds = formik?.values?.specialityIds.filter(
                                      //     (item, itemIndex) => entityIndex !== itemIndex
                                      //   );
                                      //   const updatedMatchedEntities = matchedEntities.filter(
                                      //     (item, itemIndex) => entityIndex !== itemIndex
                                      //   );
                                      //   formik.setFieldValue("specialityIds", updatedSpecialityIds, false);
                                      //   setMatchedEntities(updatedMatchedEntities);
                                      // }}
                                    >
                                      Delete
                                    </div>
                                    <div
                                      className="dropdown-item text-danger"
                                      onClick={() => {
                                        setEditRecord(matchedEntities[entityIndex]);
                                        setFacilitySpecialityDrawer(!facilitySpecialityDrawer);
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm mb-2 mb-sm-0">
                                <p>
                                  {entity?.speciality?.[`name_${language}`] || <TranslationNotAvailable />}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="px-4 drawer_area_main_div mx-3 my-2">
          <NoDataIcon />
        </div>
      )}
    </>
  );
}

export default FacilitySpecilities;
