import { Image, Pagination, Table } from "antd";
import { useCallback, useRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/request/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import * as CountryAction from "redux/country/Actions";
import { EditReportViewButton } from "components/button/Buttons";
import Select from "react-select";
import * as userActions from "redux/user/Actions";
import Tooltip from "app/dashboard/request/request.tooltip";
import {
  CalenderButtonResponse,
  CheckButtonResponse,
} from "components/button/Buttons";

function RequestsTable(props) {
  const {
    deleteRecord,
    setDeleteRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    whereText,
    setWhereText,
    getPagination,
    getPaginationCountry,
    updateModal,
    event,
    setEvent,
    eventCompleted,
    setEventCompleted,
    setUpdateModal,
    setCommentModal,
    commentModal,
    setPaymentModal,
    paymentModal,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  //Custom select for status ref
  const selectRef = useRef(null);
  // Store the previous minute in a ref
  const previousMinuteRef = useRef(new Date().getMinutes());


  const [hoveredTooltipId, setHoveredTooltipId] = useState(null);
  const [date, setDate] = useState("");
  const [country, setCountry] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAssignedOption, setSelectedAssignedOption] = useState(null);
  const [data, setData] = useState({});

  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e, entity) => {
    setData(entity);
    setHoveredTooltipId(entity.id);
    setTooltipPosition({ top: e.clientY -15, left: e.clientX - 200 }); // Adjust as necessary
  };

  let isHoveringTooltip = false; 

  const handleTooltipEnter = () => {
    isHoveringTooltip = true;
  };



  const { records, count, loading, country_records, userRecords,authState } = useSelector(
    (state) => ({
      records: state.request?.records || [],
      count: state.request?.count || 0,
      loading: state.request?.loading,
      country_records: state.country?.records || [],
      userRecords: state.user?.records || [],
      authState: state.auth 
    }),
    shallowEqual
  );
  const user = authState?.user;

  const optionsAssignedUser = userRecords.map(({ id, name, email }) => ({
    value: id,
    label: (
      <div>
        <span className="d-block h5 text-inherit mb-0">{name}</span>
        <span className="d-block fs-5 text-body">{email}</span>
      </div>
    ),
  }));

  const options = [
    { value: "open", label: "Open" },
    { value: "moved", label: "Moved" },
    { value: "completed", label: "Completed" },
  ];

  const handleUserAssignedChange = (selectedAssignedOption) => {
    setSelectedAssignedOption(selectedAssignedOption);
    if (selectedAssignedOption) {
      setAssignedTo(selectedAssignedOption.value);
    } else {
      setAssignedTo(null);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOptions((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleSelectAll = () => {
    setSelectedOptions(options.map((option) => option.value));
  };

  const handleSelectNone = () => {
    setSelectedOptions([]);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const openEventModal = (event, entityEvent) => {
    handleRowClickWithoutEvent(entityEvent);
    event.stopPropagation();
    setEvent(entityEvent);
  };

  const openAssignedToDrawer = (event, entityEvent) => {
    event.stopPropagation();
    setUpdateModal(entityEvent);
  };
  const openAddCommentDrawer = (event, requestId) => {
    event.stopPropagation();
    setCommentModal(requestId);
  };
  const openAddPaymentDrawer = (event, requestId) => {
    event.stopPropagation();
    setPaymentModal(requestId);
  };

  useEffect(
    () => {
      dispatch(CountryAction.getAll(getPaginationCountry));
      user?.role==="admin" && dispatch(userActions.getAll(""));
    },
    // eslint-disable-next-line
    []
  );

  const fetch = useCallback(
    (refresh) => {
      dispatch(actions.getAll(pagination, refresh));
    },
    // eslint-disable-next-line
    [
      pageSize,
      pageNumber,
      whereText,
      dispatch,
      eventCompleted,
      event,
      commentModal,
      paymentModal,
    ]
  );

  const countryOptions = country_records?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const resetCountry = () => {
    setCountry(null);
  };
  const resetAssignedTo = () => {
    setAssignedTo(null);
    setSelectedAssignedOption(null);
  };

  useEffect(() => {
    fetch(true);
    const interval = setInterval(() => {
      const currentTime = new Date();
      const currentMinute = currentTime.getMinutes();

      // Store the previous minute in a ref
      if (currentMinute !== previousMinuteRef.current) {
        fetch(false);
        previousMinuteRef.current = currentMinute; // Update the previous minute
      }
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on component unmount

    // eslint-disable-next-line
  }, [
    dispatch,
    pageSize,
    pageNumber,
    whereText,
    updateModal,
    eventCompleted,
    event,
    commentModal,
    paymentModal,
  ]);

  const FindCountryCode = (countryName) => {
    const country = country_records?.find(
      (record) => record.name_en === countryName
    );
    return country;
  };
  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };
  const truncateMessage = (message, charLimit) => {
    if (message.length <= charLimit) {
      return message;
    }
    return message.slice(0, charLimit) + "...";
  };

  const FindTimeAgo = (createdTime) => {
    const createdAt = new Date(createdTime);
    const now = new Date();
    const diffInSeconds = Math.floor((now - createdAt) / 1000);
    let timeAgo = "";
    if (diffInSeconds < 60) {
      timeAgo = `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      timeAgo = `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      timeAgo = `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 2592000) {
      const days = Math.floor(diffInSeconds / 86400);
      const hours = Math.floor((diffInSeconds % 86400) / 3600);
      timeAgo = `${days} day${days !== 1 ? "s" : ""}${
        hours > 0 ? ` and ${hours} hour${hours !== 1 ? "s" : ""}` : ""
      } ago`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      timeAgo = `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      timeAgo = `${years} year${years !== 1 ? "s" : ""} ago`;
    }
    return timeAgo;
  };

  useEffect(() => {
    let queryStringParts = [];

    // Add userId
    if (userId) {
      queryStringParts.push(`where[uniqueId]=${userId}`);
    }

    // Add userName
    if (userName) {
      queryStringParts.push(`userName=${userName}`);
    }

    // Add date
    if (date) {
      queryStringParts.push(`createdAt=${date}`);
    }

    // Add country
    if (country) {
      queryStringParts.push(`country=${country}`);
    }

    // Assigned To
    if (assignedTo) {
      queryStringParts.push(`where[userId]=${assignedTo}`);
    }

    // Add status
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((option) => {
        queryStringParts.push(`status[]=${option}`);
      });
    }
    // Combine all parts into a single query string
    const newQueryString =
      queryStringParts.length > 0 ? queryStringParts.join("&") : null;
    if (newQueryString) {
      setWhereText(newQueryString);
    } else {
      setWhereText(null);
    }
  }, [
    selectedOptions,
    date,
    country,
    userId,
    userName,
    setWhereText,
    assignedTo,
  ]);

  const rowClassName = (record) => {
    return record.isNew ? "row-isNew" : "";
  };

  const handleRowClickWithoutEvent = (record) => {
    if (record.isNew) {
      dispatch(actions.update(record?.id, { isNew: false }, pagination));
    }
  };

  const handleRowClick = (e, record) => {
    e.stopPropagation();
    if (record.isNew) {
      dispatch(actions.update(record?.id, { isNew: false }, pagination));
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (_, __, index) => {
        return (
          <>
            <span>{index + 1}</span>
          </>
        );
      },
      onCell: (_, entity) => {
        return {
          onClick: (e) => handleRowClick(e, _),
        };
      },
    },
    {
      title: "Created",
      dataIndex: "time",
      key: "time",
      render: (_, entity) => {
        return (
          <>
            <span>{FindTimeAgo(entity?.createdAt)}</span>
          </>
        );
      },
      onCell: (_, entity) => {
        return {
          onClick: (e) => handleRowClick(e, _),
          
        };
      },
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "25%",
      render: (_, entity) => {
        return (
          <>
            <div className="d-flex cursor-pointer">
              <Image
                className="mb-1 "
                width={15}
                preview={false}
                src={
                  FindCountryCode(entity?.country)?.code
                    ? `assets_modules/flag-icon-css/flags/1x1/${FindCountryCode(entity?.country).code.toLowerCase()}.svg`
                    : FALLBACK_IMAGE_URL // Fallback if code is undefined
                }
                fallback={FALLBACK_IMAGE_URL}
              />

              <div className="align-self-center mx-1">
                {" "}
                <span className="">
                  {entity?.uniqueId} {entity.firstName}{" "}
                  {entity.lastName && entity.lastName !== "null"
                    ? entity.lastName
                    : ""}
                </span>
              </div>
            </div>
          </>
        );
      },
      onCell: (_, entity) => {
        return {
          onClick: (e) => {
            if (!e.target.closest("img")) {
              e.stopPropagation();
            }
            handleRowClickWithoutEvent(_);
          },
        };
      },
    },
   
    {
      title: "Assigned To",
      dataIndex: "Assignedto",
      key: "Assignedto",
      width: "25%",
      render: (_, entity) => {
        return (
          <>
            <div className="flex align-self-center ">
              <span>
                {entity?.user?.name_en
                  ? entity.user.name_en
                  : entity?.user?.email
                  ? entity.user.email
                  : "Not Assigned"}
              </span>
              {user?.role==="admin" && <EditReportViewButton
                onClick={(e) => openAssignedToDrawer(e, entity)}
                props={props}
                title="Assigned To"
              />}
            </div>
          </>
        );
      },
      onCell: (_, entity) => {
        return {
          onClick: (e) => handleRowClick(e, _),
        };
      },
    },
    {
      title: "Client Request",
      dataIndex: "reason",
      key: "reason",
      render: (_, entity) => {
        return (
          <>
            <span>{truncateMessage(entity.message, 20)}</span>
          </>
        );
      },
      onCell: (_, entity) => {
        return {
          onClick: (e) => handleRowClick(e, _),
        };
      },
    },
   
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "170px",
      render: (_, entity) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ position: "relative" }}
          >
          
            <div
              key={entity.id}
              onMouseEnter={handleTooltipEnter} // Handle entering the tooltip
            >
            <CalenderButtonResponse  onMouseEnter={(e) => handleMouseEnter(e, entity)} />
            </div>
            {entity.status === "open" && (
              <CheckButtonResponse
                onClick={() => {
                  setEventCompleted(entity);
                  handleRowClick(entity);
                }}
                title="Status"
              />
            )}
            <span>{entity.status}</span>
          </div>
        );
      },
      onCell: (_, entity) => ({
        onClick: (e) => handleRowClick(e, _),
      }),
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div
        className="pb-2 pt-3 table_main_div request-main-div"
        style={{ height: "contain" }}
      >
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
          </div>
        </div>

        <div className="row mx-2 " style={{ paddingRight: "50px" }}>
        <div className="col-12 col-md-auto">
            <span className="drawer_span">Country</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="countries"
                value={
                  countryOptions?.find((option) => option?.label === country) ||
                  null
                }
                options={countryOptions}
                onChange={(selectedOption) => {
                  setCountry(selectedOption ? selectedOption.label : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetCountry}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Assigned To</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                className="drawer_input"
                menuPosition={"fixed"}
                placeholder={
                  updateModal?.user?.email ? updateModal.user.email : ""
                }
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: "13px",
                    color: "#677788",
                    width: "8vw",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    fontSize: "13px",
                    color: "#677788",
                  }),
                }}
                name={"assignedToo"}
                value={selectedAssignedOption ? selectedAssignedOption : null}
                options={optionsAssignedUser}
                onChange={handleUserAssignedChange}
                classNamePrefix="select"
                components={{
                  IndicatorSeparator: () => null,
                }}
                filterOption={(option, inputValue) =>
                  // Custom filter logic for searching by email within the label
                  option.data.label.props.children[1].props.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
              <b
                onClick={resetAssignedTo}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto" ref={selectRef}>
            <span className="drawer_span">Status</span>
            <div className="custom-select-container-select">
              <div
                className="selected-items"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedOptions.length > 0
                  ? selectedOptions
                      .map(
                        (option) =>
                          options.find((o) => o.value === option)?.label
                      )
                      .join(", ")
                  : "Select Status"}
              </div>
              {isDropdownOpen && (
                <div className="dropdown-custom">
                  <ul className="checkbox-list">
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.length === options.length}
                          onChange={handleSelectAll}
                        />
                        Select All
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.length === 0}
                          onChange={handleSelectNone}
                        />
                        Select None
                      </label>
                    </li>
                    {options.map((option) => (
                      <li key={option.value}>
                        <label>
                          <input
                            type="checkbox"
                            value={option.value}
                            checked={selectedOptions.includes(option.value)}
                            onChange={() => handleOptionChange(option.value)}
                          />
                          {option.label}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Date</span>
            <div className="custom-select-container-select">
              <input
                type="date"
                className="form-control"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>
         
          <div className="col-12 col-md-auto">
            <span className="drawer_span">User Id</span>
            <div className="custom-select-container">
              <input
                type="text"
                className="form-control"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">User Name</span>
            <div className="custom-select-container">
              <input
                type="text"
                className="form-control"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </div>
     
        </div>
        <div className="domain_history table_main py-3 px-2">
          <Table
            className="components-table-demo-nested table_tag"
            size="small"
            // scroll={{ x: 'max-content' }}
            scroll={{ x: "calc(100vw - 420px)" }}
            loading={loading?.getAll || false}
            pagination={false}
            dataSource={records}
            rowClassName={rowClassName}
            columns={columns}
            layout="fixed" 
            expandIconColumnIndex={-1}
            expandable={{
              expandedRowRender: (records) => (
                <>
                  <div className="container-fluid">
                    <div className="row mt-3">
                      <div className="col-lg-4 ">
                        {/****** Basic Info *****/}
                        <div className="card mb-3 mb-lg-5">
                          <div className="card-header card-header-content-between">
                            <h5>
                              User{" "}
                              <span className="ml-1" style={{ color: "blue" }}>
                                {records?.uniqueId}
                              </span>
                            </h5>
                          </div>
                          <div className="card-body">
                            <table className="my-custom-table">
                              <thead>
                                <tr>
                                  <th>User Name</th>
                                  <td>
                                    {records.firstName}{" "}
                                    {records.lastName &&
                                    records.lastName !== "null"
                                      ? records.lastName
                                      : ""}
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>Country</th>
                                  <td>
                                    <>
                                      <Image
                                        className="mb-1 "
                                        width={15}
                                        preview={false}
                                        src={
                                          FindCountryCode(records?.country)?.code
                                            ? `assets_modules/flag-icon-css/flags/1x1/${FindCountryCode(records?.country).code.toLowerCase()}.svg`
                                            : FALLBACK_IMAGE_URL // Fallback if code is undefined
                                        }
                                        fallback={FALLBACK_IMAGE_URL}
                                      />
                                    </>
                                    <span className="mx-2">
                                      {records.country}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Phone</th>
                                  <td>{records.phone ? records.phone : "-"}</td>
                                </tr>
                                <tr>
                                  <th>Email</th>
                                  <td>{records.email ? records.email : "-"}</td>
                                </tr>
                                <tr>
                                  <th>Status</th>
                                  <td>
                                    {records.status ? records.status : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>IP</th>
                                  <td>
                                    {records.ip ? records.ip : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Browser</th>
                                  <td>
                                    {records.browser ? records.browser : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Device</th>
                                  <td>
                                    {records.device ? records.device : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Page URL</th>
                                  <td>
                                    {records.pageUrl ? records.pageUrl : ""}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id={`comment-tab-${records.id}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#comment-${records.id}`}
                              type="button"
                              role="tab"
                              aria-controls="comment"
                              aria-selected="false"
                            >
                              Comment
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id= {`timeline-tab-${records.id}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#timeline-${records.id}`}
                              type="button"
                              role="tab"
                              aria-controls="timeline"
                              aria-selected="false"
                            >
                              Timeline
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id= {`payment-tab-${records.id}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#payment-${records.id}`}
                              type="button"
                              role="tab"
                              aria-controls="payment"
                              aria-selected="false"
                            >
                              Payment
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id= {`reason-tab-${records.id}`}
                              data-bs-toggle="tab"
                              data-bs-target={`#reason-${records.id}`}
                              type="button"
                              role="tab"
                              aria-controls="reason"
                              aria-selected="false"
                            >
                              Client Request
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id={`comment-${records.id}`}
                            role="tabpanel"
                            aria-labelledby={`comment-tab-${records.id}`}

                          >
                            <div className="row mt-5">
                              <div className="col-lg-12-">
                                {/****** Basic Info *****/}
                                <div className="card mb-3 mb-lg-5">
                                  <div className="card-header card-header-content-between">
                                    <h5>Comment</h5>
                                    <h3
                                      role="button"
                                      onClick={(e) =>
                                        openAddCommentDrawer(e, records?.id)
                                      }
                                      title="Add Comment"
                                    >
                                      {" "}
                                      +
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    <table className="my-custom-table">
                                      <thead>
                                        <tr>
                                          <th>Time</th>
                                          <th>Author</th>
                                          <th>Text</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {records.requestComment.map(
                                          (commentRecord, index) => (
                                            <tr key={index}>
                                              <td>
                                                {FindTimeAgo(
                                                  commentRecord?.createdAt
                                                )}
                                              </td>
                                              <td>
                                                {commentRecord?.user?.name}
                                              </td>
                                              <td>{commentRecord.text}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id={`timeline-${records.id}`}
                            role="tabpanel"
                            aria-labelledby={`timeline-tab-${records.id}`}

                          >
                            <div className="row mt-5">
                              <div className="col-lg-12">
                                <div className="card mb-3 mb-lg-5 ">
                                  <div className="card-header card-header-content-between">
                                    <h5>Request Logs</h5>
                                  </div>

                                  <div className="card-body my-custom-table-container">
                                    <table className="my-custom-table">
                                      <thead>
                                        <tr>
                                          <th>Created at</th>
                                          <th>User</th>
                                          <th>Message</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {records.requestLog.map(
                                          (logRecord, index) => (
                                            <tr key={index}>
                                              <td>
                                                {FindTimeAgo(
                                                  logRecord?.createdAt
                                                )}
                                              </td>
                                              <td>{logRecord?.user?.name}</td>
                                              <td>{logRecord.message}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id={`payment-${records.id}`}
                            role="tabpanel"
                            aria-labelledby={`payment-tab-${records.id}`}

                          >
                            <div className="row mt-5">
                              <div className="col-lg-12-">
                                {/****** Basic Info *****/}
                                <div className="card mb-3 mb-lg-5">
                                  <div className="card-header card-header-content-between">
                                    <h5>Payment</h5>
                                    <h3
                                      role="button"
                                      onClick={(e) =>
                                        openAddPaymentDrawer(e, records?.id)
                                      }
                                      title="Add Payment"
                                    >
                                      {" "}
                                      +
                                    </h3>
                                  </div>
                                  <div className="card-body">
                                    <table className="my-custom-table">
                                      <thead>
                                        <tr>
                                          <th>Time</th>
                                          <th>Author</th>
                                          <th>Country</th>
                                          <th>Treatment</th>
                                          <th>Amount</th>
                                          <th>Comment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {records.requestPayment.map(
                                          (paymentRecord, index) => (
                                            <tr key={index}>
                                              <td>
                                                {FindTimeAgo(
                                                  paymentRecord?.createdAt
                                                )}
                                              </td>
                                              <td>
                                                {paymentRecord?.user?.name}
                                              </td>
                                              <td>
                                                {
                                                  paymentRecord?.country
                                                    ?.name_en
                                                }
                                              </td>
                                              <td>
                                                {
                                                  paymentRecord?.treatment
                                                    ?.name_en
                                                }
                                              </td>
                                              <td>{paymentRecord.amount}</td>
                                              <td>
                                                {paymentRecord.description}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id={`reason-${records.id}`}
                            role="tabpanel"
                            aria-labelledby={`reason-tab-${records.id}`}

                          >
                            <div className="row mt-5">
                              <div className="col-lg-12">
                                <div className="card mb-3 mb-lg-5 ">
                                  <div className="card-header card-header-content-between">
                                    <h5>Client Request</h5>
                                  </div>

                                  <div className="card-body my-custom-table-container">
                                    <table className="my-custom-table">
                                      <thead>
                                        <tr>
                                          <th>Client Request</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr key={1}>
                                          <td>{records?.message}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ),
              rowExpandable: (records) => records.id !== "Not Expandable",
            }}
            expandRowByClick
            bordered
          />
          <Tooltip
            data={data}
            tooltipPosition={tooltipPosition}
            setData={setData}
            setHoveredTooltipId={setHoveredTooltipId}
            openEventModal={openEventModal}
            hoveredTooltipId={hoveredTooltipId}
          />

          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default RequestsTable;
