import React from "react";
import { Spin } from "antd";
function DashboardCards({ statistics, loading = false }) {
  const cards = [
    {
      name: "Total Countries",
      count: parseInt(statistics?.country || 0),
    },
    {
      name: "Total Cities",
      count: parseInt(statistics?.city || 0),
    },
    {
      name: "Total Treatments",
      count: parseInt(statistics?.treatment || 0),
    },
    {
      name: "Total Specialities",
      count: parseInt(statistics?.speciality || 0),
    },
    {
      name: "Total Doctors",
      count: parseInt(statistics?.doctor || 0),
    },
    {
      name: "Total  Facilities",
      count: parseInt(statistics?.facility || 0),
    },
    {
      name: "Total Request",
      count: parseInt(statistics?.request || 0),
    },
    {
      name: "Total Users",
      count: parseInt(statistics?.user || 0),
    },
    {
      name: "Total Blogs",
      count: parseInt(statistics?.blog || 0),
    },
  ];

  return (
    <>
      <div className="grey-bg container-fluid">
        <section>
          <div className="row mx-0">
            {cards.map(({ count = 0, name = "" }, index) => (
              <div className="col-xl-4 my-2 col-sm-6 col-12" key={index}>
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-pencil primary font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>
                            {loading ? (
                              <>
                                <Spin />
                              </>
                            ) : (
                              count
                            )}
                          </h3>
                          <span>{name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default DashboardCards;
