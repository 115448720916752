import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {
    create: false,
    update: false,
    remove: false,
    getAll: false,
  },
  records: null,
  count: null,
};

export const serviceLanguageSlice = createSlice({
  name: "service-language",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      const {
        getAll = null,
        create = null,
        update = null,
        remove = null,
      } = action.payload;
      state.loading = {
        getAll: getAll != null ? getAll : state.loading?.getAll || false,
        create: create != null ? create : state.loading.create || false,
        update: update != null ? update : state.loading.update || false,
        remove: remove != null ? remove : state.loading?.remove || false,
      };
    },
    getAll: (state, action) => {
      const { records, count } = action.payload;
      state.records = records;
      state.count = count;
    },
  },
});
