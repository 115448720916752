import WhyChooseUsDrawer from "app/dashboard/common/why-choose-us/why-choose-us.drawer";
import NoDataIcon from "components/animation-icons/no-data-icon";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";
import React,{useState,useEffect,useCallback} from "react";
import { shallowEqual,useDispatch, useSelector } from "react-redux";
import { createQueryParams } from "utils/helpers/helper-functions";
import * as actions from "redux/common/why-choose-us/Actions";


function WhyChooseUs({ formik, view, recordId }) {
  const dispatch = useDispatch();
  const { Segment, language } = LanguageSegment();
  const [drawer, setDrawer] = React.useState(false);
  const [editRecord, setEditRecord] = useState(null);


  const handleEdit = (newRecord, id) => {
    newRecord.id = id;
    setEditRecord(newRecord);
    setDrawer(false);
    formik.setFieldValue("whyChooseUsIds", formik.values.whyChooseUsIds.map(dep => {
      if (dep.value === newRecord.id) {
        return { ...dep, label: newRecord.name_en };
      }
      return dep;
    }));
  };

  const handleCreate = (newRecord) => {
    const exists = newRecord ? formik.values.whyChooseUsIds.some(dep => dep.value === newRecord.id) : null;
    if (!exists) {
      formik.setFieldValue("whyChooseUsIds", [
        ...formik.values.whyChooseUsIds,
        { value: newRecord.id, label: newRecord.text_en }
      ]); 
    }
  };
  const getPagination = () => {
    const limit = 100000;
    let offset = 0;
    const where=`where[facilityWhyChooseUs][facilityId]=${recordId}`;
    const text = '';
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations,where);
    return { limit, offset, text, relations, query };
  };
  
  const fetchWhyChooseus = useCallback(() => {
    dispatch(actions.getAll({  query: `where[facilityWhyChooseUs][facilityId]=${recordId}` }));
  }, 
  // eslint-disable-next-line
  [dispatch,recordId]);
  useEffect(() => {
    recordId && fetchWhyChooseus()  ;
  }, 
  // eslint-disable-next-line
  []);

  const { entity } = useSelector((state) => ({ entity: state.whyChooseUs?.records }),shallowEqual);

  const handleDelete = (deleteRec) => {
    dispatch(actions.remove(`${deleteRec?.id}?facilityId=${recordId}`, setEditRecord, getPagination()));
  };
  return (
    <>
      <WhyChooseUsDrawer
        setDrawer={setDrawer}
        drawer={drawer}
        getPagination={getPagination}
        setEditRecord={setEditRecord}
        onEdit={handleEdit}
        editRecord={editRecord}
        onCreate={handleCreate} 
        recordId={recordId}


      />

      <div className="language-segmented my-2 drawer_area_main_div mx-3 px-4">
        {Segment}
      </div>
      <div className="drawer_area_main_div mx-3 my-2 px-3">
        <div className="d-flex w-100">
         
          <div className="flex-grow-1 w-10 align-self-center text-end mx-2">
            <span
              onClick={() => setDrawer(!drawer)}
              className="btn btn-primary btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#newProjectModal"
            >
              <i className="bi-plus me-1"></i> Add
            </span>
          </div>
        </div>
      </div>
      {/* LIST */}
      {entity?.length ? (
        <>
          <div className="content container-fluid px-3">
            <div className="row justify-content-lg-center">
              <div className="col-lg-12 ">
                <div className="tab-content" id="profileTeamsTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row row-cols-1 ">
                      {entity.map(
                        ( index , entityIndex) => (
                          <div className="col mb-3 ">
                            <div className="card card-body">
                              <div className="row align-items-md-center">
                                <div className="col-3 col-md-auto order-md-last text-end">
                                  <div className="dropdown">
                                    <button
                                      disabled={view}
                                      type="button"
                                      className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                      id="teamsListDropdown1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="bi-three-dots-vertical"></i>
                                    </button>

                                    <div
                                      className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                                      aria-labelledby="teamsListDropdown1"
                                    >
                                      <div
                                        className="dropdown-item text-danger"
                                        onClick={() => {
                                          handleDelete(index);
                                        }}
                                      >
                                        Delete
                                      </div>
                                      <div
                                      className="dropdown-item text-danger"

                                      onClick={() => {
                                        setEditRecord( entity[entityIndex]);
                                        setDrawer(!drawer);
                                      }}
                                    >
                                      Edit
                                    </div>

                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm mb-2 mb-sm-0 ">
                                  <p>
                                    {entity[entityIndex][`text_${language}`] || (
                                      <TranslationNotAvailable />
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="px-4 drawer_area_main_div mx-3 my-2">
            <NoDataIcon />
          </div>
        </>
      )}
    </>
  );
}

export default WhyChooseUs;
