import { Drawer, Image, Space, Spin, Upload, notification } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/treatment/Actions";
import * as uploadActions from "redux/upload/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";
import Select from "react-select";
import * as specialitiesAction from "redux/specialities/Actions";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";

function TreatmentDrawer(props) {
  const { edit, setEdit, view, setView, getPagination, drawer, setDrawer } =
    props;
  const record = edit || view;

  const { loading ,speciality} = useSelector(
    (state) => ({
      loading: state.treatment?.loading,
      speciality: state.speciality?.records,
    }),
    shallowEqual
  );

  const {
    name_en = "",
    name_ar = "",
    name_de = "",
    name_ru = "",
    name_uk = "",
    specialityId=null,
    image = "",
    id = null,
    isFeatured = false,
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = edit ? loading.update : loading.create;
  const title = `${view ? " View " : edit ? " Edit " : " Add "} Treatment`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(edit ? image : "");
  const [open, setOpen] = useState(false);


  useEffect(() => {
    if (drawer) {
      const query = `limit=1000000&offset=0`;
      dispatch(specialitiesAction.getAll({query}))
    }
  }, 
  // eslint-disable-next-line
  [drawer]);

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewImage("");
    setImage(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEdit(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    setPreviewImage(image || "");
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  },
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",
    image: image || "",
    isFeatured: isFeatured || false,
    specialityId:specialityId||null,
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),
    specialityId: Yup.string().nullable().required("Speciality is required"),
    image: Yup.string().notRequired(),
    isFeatured: Yup.boolean().notRequired(),
  });

  const specialities = speciality?.map(({ id: value, name_en }, index) => ({
    value,
    label: name_en,
    index,
  }));

  const onSubmit = async (values) => {
    setOpen(false)
    dispatch(actions.loading(edit ? { update: true } : { create: true }));
    if (uploadedImage) {
      const Images = await dispatch(uploadActions.upload([uploadedImage]));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        dispatch(actions.loading(edit ? { update: false } : { create: false }));
        return;
      }
      values.image = Images[0] || "";
    }

    const action = edit
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={500}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!edit && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">
                    Name <span className="form-label-secondary">(English)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_en"
                    className={`form-control ${
                      formik.errors.name_en && formik.touched.name_en
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_en}
                    placeholder="Enter treatment name in (English)"
                  />
                  {formik.errors.name_en && formik.touched.name_en && (
                    <ErrorMessage message={formik.errors.name_en} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Arabic)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ar"
                    className={`form-control ${
                      formik.errors.name_ar && formik.touched.name_ar
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ar}
                    placeholder="Enter treatment name in (Arabic)"
                  />
                  {formik.errors.name_ar && formik.touched.name_ar && (
                    <ErrorMessage message={formik.errors.name_ar} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Russian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ru"
                    className={`form-control ${
                      formik.errors.name_ru && formik.touched.name_ru
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ru}
                    placeholder="Enter treatment name in (Russian)"
                  />
                  {formik.errors.name_ru && formik.touched.name_ru && (
                    <ErrorMessage message={formik.errors.name_ru} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(German)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_de"
                    className={`form-control ${
                      formik.errors.name_de && formik.touched.name_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_de}
                    placeholder="Enter treatment name in (German)"
                  />
                  {formik.errors.name_de && formik.touched.name_de && (
                    <ErrorMessage message={formik.errors.name_de} />
                  )}
                </div>
                 {/* /********** INPUT ********/}
                 <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Ukrainian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_uk"
                    className={`form-control ${
                      formik.errors.name_uk && formik.touched.name_uk
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_uk}
                    placeholder="Enter treatment name in (Ukrainian)"
                  />
                  {formik.errors.name_uk && formik.touched.name_uk && (
                    <ErrorMessage message={formik.errors.name_uk} />
                  )}
                </div>
                <div className="my-2">
                  <span className="drawer_span required">
                  Specilities
                  </span>
                    <Select
                      isDisabled={view}
                      isMulti={false}
                      name="Specilities"
                      value={specialities?.find((option) => option?.value === formik?.values?.specialityId) || null}
                      options={specialities}
                      onChange={(selectedOption) => {
                        formik.setFieldValue("specialityId", selectedOption ? selectedOption.value : null);
                      }}
                      className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                      classNamePrefix="select"
                    />    
                     {formik.errors.specialityId && formik.touched.specialityId && (
                    <ErrorMessage message={formik.errors.specialityId} />
                  )}               
                </div>

                {/* /********** RIGHT ********/}
                <div className="form-check form-switch my-2">
                  <input
                    disabled={view}
                    name="isFeatured"
                    type="checkbox"
                    role="switch"
                    checked={formik.values.isFeatured}
                    className={` drawer_input form-check-input drawer_input ${
                      formik.errors.isFeatured && formik.touched.isFeatured
                        ? "input_error"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("isFeatured", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.isFeatured}
                  />
                  <span className="drawer_span">
                    Is Featured{" "}
                    <span className="form-label-secondary">
                      (Display on navbar ?)
                    </span>
                  </span>
                </div>
                {formik.errors.isFeatured && formik.touched.isFeatured && (
                  <ErrorMessage message={formik.errors.isFeatured} />
                )}

                <div className=" m-auto my-4 text-center ww-50">
                  <Image
                    preview={previewImage ? true : false}
                    width={150}
                    height={150}
                    src={previewImage || FALLBACK_IMAGE_URL}
                    fallback={FALLBACK_IMAGE_URL}
                  />

                  <div className="mb-4 mt-2 d-flex-custom">
                    {!view && (
                      <>
                        <Upload
                          showUploadList={false}
                          maxCount={1}
                          onChange={onChange}
                          disabled={isLoading || view}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>

                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {/* /********** BUTTON ********/}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default TreatmentDrawer;
