import { Popover } from "antd";
import { shallowEqual, useSelector } from "react-redux";
import Select, { components } from "react-select";

export const ServicePriceSelect = ({ name, value, onChange }) => {
  const { servicePrice } = useSelector(
    (state) => ({ servicePrice: state.servicePrice?.records }),
    shallowEqual
  );

  const servicePrices = servicePrice?.map((entity, index) => ({
    value: entity?.id,
    label: entity?.name_en,
    index,
    component: <ServicePriceCard {...entity} />,
  }));

  const Option = (props) => (
    <Popover content={<>{props.data.component}</>}>
      <components.Option {...props} />
    </Popover>
  );

  return (
    <>
      <Select
        components={{ Option }}
        isMulti
        name={name}
        value={value}
        onChange={onChange}
        options={servicePrices}
        className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
        classNamePrefix="select"
      />
    </>
  );
};

const ServicePriceCard = ({ name_en, type, price }) => (
  <div className="row align-items-md-center">
    <div className="d-flex justify-content-between">
      <h4>
        <span style={{ color: "rgba(113, 134, 157)" }}>{name_en}</span>
      </h4>

      <div className="col-sm-auto">$ {price}</div>
    </div>
    <span className="badge bg-soft-secondary text-secondary p-2" >
      {type}
    </span>
  </div>
);
