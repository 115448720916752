import { message } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Logo from "../../assets/images/logo1.svg";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import { ResetPassword } from "../../firebase/firebase";
import "./forget-password.style.css";
export default function ForgetPassword() {
  const [btnInstuction, setbtnInstuction] = useState("Send Reset Instruction");
  const [messageApi, contextHolder] = message.useMessage();
  //input validations
  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter correct email format"),
  });
  const SubmitHandler = async (values) => {
    messageApi.open({
      type: "loading",
      content: "Sending Email...",
      duration: 0,
    });
    try {
      await ResetPassword(values.email);
      message.destroy();
      messageApi.open({
        type: "success",
        content: "Email sent successfully",
      });
      setbtnInstuction("Email sent");
    } catch (error) {
      message.destroy();
      messageApi.open({
        type: "error",
        content: "User not found",
      });
    }
  };

  //formik Handler
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: ForgetPasswordSchema,
    onSubmit: SubmitHandler,
  });

  return (
    <>
      {contextHolder}
      <main>
        {/* <!-- Content --> */}
        <div className="container-fluid">
          {/* <!-- row start --> */}
          <div className="row ">
            <div className="col-lg-6 forget_password_page_min_height forget_password_page_left_div d-lg-flex flex-column justify-content-center ">
              <div className="forget_password_page_left_inner_div    ">
                <form onSubmit={formik.handleSubmit}>
                  <p className="forget_password_page_heading">
                    Forget Password
                  </p>
                  <p className="forget_password_left_div_details">
                    Enter the email address you used when you subscribed and
                    we’ll send you instructions to reset your password.
                  </p>
                  {/* <p className="forget_password_left_div_details">
                    {" "}
                    For security reasons, we do NOT store your password. So rest
                    assured that we will never send your password via email.
                  </p> */}

                  {/* <!-- Email Input  -->*/}
                  <p className="forget_password_left_div_labels_Email">Email</p>
                  <input
                    type="text"
                    className={`forget_password_left_div_input ${
                      formik.errors.email && formik.touched.email
                        ? "input_error"
                        : ""
                    }`}
                    placeholder="name@example.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    name="email"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMessage message={formik.errors.email} />
                  )}
                  <div>
                    <button
                      type="submit"
                      disabled={
                        btnInstuction !== "Send Reset Instruction"
                          ? true
                          : false
                      }
                      className="   btn-theme forget_password_page_left_div_submit_btn  "
                    >
                      {btnInstuction}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div className="col-lg-6 d-flex  d-none d-lg-flex flex-column justify-content-center align-items-center bg_theme_color_blue forget_password_page_min_height">
              <img src={Logo} alt="loading..." />

              <div>
                <Link to="/login">
                  <button
                    type="button"
                    className=" forget_password_page_right_div_btn "
                  >
                    Login
                  </button>
                </Link>
                {/* <Link to="/signup">
                  <button
                    type="button"
                    className="    forget_password_page_right_div_btn  "
                  >
                    Sign Up
                  </button>
                </Link> */}
              </div>
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- row end --> */}
        </div>
        {/* <!-- End Content --> */}
      </main>
    </>
  );
}
