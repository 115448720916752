import FaqsDrawer from "app/dashboard/common/faqs/faqs.drawer";
import NoDataIcon from "components/animation-icons/no-data-icon";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";
import React, {useState} from "react";
import { shallowEqual, useSelector ,useDispatch} from "react-redux";
import { createQueryParams } from "utils/helpers/helper-functions";
import * as actions from "redux/common/faqs/Actions";

function FAQs({ formik, view , recordId}) {
  const dispatch = useDispatch();
  const { Segment, language } = LanguageSegment();
  const [drawer, setDrawer] = React.useState(false);
  const [editRecord, setEditRecord] = useState(null);

  
  const handleEdit = (newRecord, id) => {
    newRecord.id = id;
    setEditRecord(newRecord);
    setDrawer(false);
  };



  const getPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const where=`where[facilityFaq][facilityId]=${recordId}`;
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations,where);
    return { limit, offset, text, relations, query };
  };

  const { faq } = useSelector(
    (state) => ({
      faq: state.faqs?.records,
    }),
    shallowEqual
  );
  const handleDelete = (deleteRec) => {
    dispatch(actions.remove(`${deleteRec?.id}?facilityId=${recordId}`, setEditRecord, getPagination()));
  };
  return (
    <>
      <FaqsDrawer
        setDrawer={setDrawer}
        drawer={drawer}
        getPagination={getPagination}
        setEditRecord={setEditRecord}
        onEdit={handleEdit}
        editRecord={editRecord}
        recordId={recordId}
        from={"facility"}

      />

      <div className="language-segmented drawer_area_main_div mx-3 px-3">
        {Segment}
      </div>
      <div className="drawer_area_main_div mx-3 my-2 px-3 text-right">
        <div className="d-flex justify-content-end">
          <div className="col-sm-auto d-flex align-items-center mx-2">
            <span
              onClick={() => setDrawer(!drawer)}
              className="btn btn-primary btn-theme cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#newProjectModal"
            >
              <i className="bi-plus me-1"></i> Add
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 drawer_area_main_div mx-3 my-2">
        <div className="accordion" id="accordionExample">
          {faq?.length ? (
            <>
              {faq.map((faqEntity, entityIndex) => {
                return (
                  <div className="d-flex my-2">
                    <div className="accordion-item w-100">
                      <div
                        className="accordion-header"
                        id={`heading_${entityIndex}`}
                      >
                        <span
                          className="accordion-button cursor-pointer"
                          role="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${entityIndex}`}
                          aria-expanded="true"
                          aria-controls={`collapse${entityIndex}`}
                        >
                          {faqEntity[`question_${language}`] ? (
                            faqEntity[`question_${language}`]
                          ) : (
                            <TranslationNotAvailable />
                          )}
                        </span>
                      </div>
                      <div
                        id={`collapse${entityIndex}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`heading_${entityIndex}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {faqEntity[`answer_${language}`] ? (
                            faqEntity[`answer_${language}`]
                          ) : (
                            <TranslationNotAvailable />
                          )}
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-3 col-md-auto order-md-last text-end">
                      <div className="dropdown">
                        <button
                          disabled={view}
                          type="button"
                          className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                          id="teamsListDropdown1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi-three-dots-vertical"></i>
                        </button>

                        <div
                          className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                          aria-labelledby="teamsListDropdown1"
                        >
                          <div
                            className="dropdown-item text-danger"
                            onClick={() => {
                              handleDelete(faqEntity);
                            }}
                          >
                            Delete
                          </div>
                          <div
                            className="dropdown-item text-danger"

                            onClick={() => {
                              setEditRecord(faqEntity);
                              setDrawer(!drawer);
                            }}
                          >
                            Edit
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <NoDataIcon />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FAQs;
