import GoogleMapReact from "google-map-react";
import React from "react";

const AnyReactComponent = ({ text }) => (
  <div
    // className="bg-danger"
    style={{ marginTop: "-20px", marginLeft: "-10px" }}
  >
    <i
      className="bi bi-geo-alt-fill  "
      style={{ fontSize: "20px", color: "#f30301" }}
    ></i>
  </div>
);
// const AnyReactComponent = ({ text }) => (
//   <div className="bg-danger">
//     {/* {text} */}
//     <i
//       className="bi bi-geo-alt-fill  "
//       style={{ fontSize: "40px", color: "#f30301" }}
//     ></i>
//   </div>
// );

export default function GoogleMapComponent(props) {
  const { location, onChange, disabled } = props;
  return (
    // Important! Always set the container height explicitly
    <div
      style={{ height: "20rem", width: "100%", opacity: disabled ? 0.5 : 1 }}
    >
      <div className="d-flex  justify-content-between ">
        <div className="h4">
          {" "}
          <span className="badge bg-soft-secondary text-secondary">
            Lat: {location.lat}
          </span>
        </div>
        <div className="h4">
          {" "}
          <span className="badge bg-soft-secondary text-secondary">
            Long: {location.lat}
          </span>
        </div>
      </div>

      <GoogleMapReact
        // style={{ borderRadius: "2rem" }}
        bootstrapURLKeys={{
          key:
            "AIzaSyDMHNBoOdz5Kn_f-GUW7Vr97kkqryHYuEw" ||
            process.env.REACT_GOOGLE_MAP_API_KEY,
        }}
        defaultCenter={location}
        defaultZoom={3}
        onClick={disabled ? () => {} : onChange}
      >
        <AnyReactComponent lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  );
}
