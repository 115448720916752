import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Spin } from "antd";

export const SaveConfirmationDialog = ({
  loading = false,
  open,
  onClose,
  onSave,
  title,
  content,
  onCancelLabel,
  onConfirmLabel,
}) => {
  return (
    <Dialog
      open={open ? true : false}
      PaperProps={{ style: { borderRadius: 17, width: "400px" } }}
    >
      <Card className="card pb-2" style={{ width: "400px" }}>
        <div
          className=" pt-3 pb-1 border-bottom border-1  text-center"
          style={{ backgroundColor: "white" }}
        >
          <label className="h3 text-grey text-center">{title}</label>
        </div>
        <div className="card-body overflow-auto text-center">
          <label className="text_16_500">{content}</label>
        </div>
        <div className="d-flex justify-content-center mb-2">
          <button
            onClick={() => onClose(false)}
            className="btn bg-transparent py-2 margin_right_10 border p-5"
          >
            {onCancelLabel}
          </button>
          <button
            disabled={loading}
            style={{ background: "#f30301" }}
            onClick={onSave}
            className="btn py-2 text-white p-5"
          >
            {loading ? <Spin /> : onConfirmLabel}
          </button>
        </div>
      </Card>
    </Dialog>
  );
};
