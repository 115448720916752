import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";

function RichTextEditor(props) {
  const {  value, setValue, disabled,  className } = props;
  return (
    <>
      <div className="App">
        <CKEditor
          style={{ border: "1px solid red" }}
          className={className}
          // config={{ toolbar: ["bold", "italic"] }}
          disabled={disabled}
          editor={ClassicEditor}
          data={value}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
            setValue(data);
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
    </>
  );
}

export default RichTextEditor;
