import React from 'react';

const Tooltip = ({ data,setData, setHoveredTooltipId,tooltipPosition, openEventModal, hoveredTooltipId }) => {
  const handleMouseLeave = () => {
    setData({});
    setHoveredTooltipId(null);
  };
  return (
    hoveredTooltipId && data?.id === hoveredTooltipId && (
      <div
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'absolute',
          top: tooltipPosition.top,
          left: tooltipPosition.left,
          padding: '5px 10px',
          backgroundColor: 'white',
          color: '#000',
          border: '1px solid #000',
          borderRadius: '6px',
          transition: 'opacity 0.3s',
          zIndex: 1000,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          whiteSpace: 'nowrap',
          right: data?.requestEvent?.length === 0 && '200px',

        }}
      >
        {/* Check if the data has any events */}
        {data.requestEvent && data.requestEvent.length > 0 ? (
          data.requestEvent.slice(-10).map((event, index) => (
            <div key={index} style={{ marginBottom: '5px' }}>
              <span style={{ marginRight: '5px', color: 'black' }}>•</span>
              <span>
                {new Date(`${event.date}T${event.time}Z`).toLocaleDateString()}{" "}
                {new Date(`${event.date}T${event.time}Z`).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}{" "}
                - {event.status.replace("-", " ")}
              </span>
            </div>
          ))
        ) : (
          <span className='no-event' >No Event Yet</span>
        )}

        {/* Add Event Button */}
        <button
          type="button"
          onClick={(e) => openEventModal(e, data)}
          title="Event"
          className="btn btn-link"
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        >
          + Add Event
        </button>
      </div>
    )
  );
};

export default Tooltip;
