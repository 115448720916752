import { useState } from "react";
import { useSelector } from "react-redux";
import { Spin, notification } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as actions from "redux/request/Actions";
import { useDispatch } from "react-redux";

const EventCompletedModal = ({ ...props }) => {
  const { editRecord } = props;
  const {
    status = "new-lead",
    message = null,
  } = editRecord || {};

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    status: status || "new-lead",
    message: message || "",
  };

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Event Status is required"),
    message: Yup.string().required("Message is required"),
  });
  const resetForm = () => {
    formik.resetForm();
  };

  const onSubmit = async (values) => {
    dispatch(actions.loading({ create: true }));

    let payload = { ...values };
    const action = actions.createEventCompleted(
      props.eventCompleted?.id,
      payload,
      props.setEventCompleted,
      resetForm,
      props.pagination
    );
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <div className="container-fluid ">
      <h1 className="text-center t-2 ">Complete Task</h1>
      <form onSubmit={formik.handleSubmit}>
       
        <div className="row mt-4">

          <div className="col-md-12 col-sm-12">
            <h6>Status</h6>
            <select
              className="form-control"
              value={formik.values.status}
              name="status"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="new-lead">New Lead</option>
              <option value="no-answer">No Answer</option>
              <option value="duplicate-task">Duplicate Task</option>
              <option value="not-interested">Not Interested</option>
              <option value="Follow-up-call-back">Follow-Up Call Back</option>
              <option value="wrong-number">Wrong Number</option>
              <option value="scheduled">Scheduled</option>
              <option value="confirmed">Confirmed</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
              <option value="rescheduled">Rescheduled</option>
              <option value="pending">Pending</option>
              <option value="no-show">No Show</option>
              <option value="in-progress">In Progress</option>
              <option value="arrived">Arrived</option>
              <option value="pending-contact">Pending Contact</option>
              <option value="patient-confirmed">Patient Confirmed</option>
              <option value="patient-not-reachable">
                Patient Not Reachable
              </option>
            </select>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <h6>Add Your Comment</h6>
            <textarea
              placeholder=""
              type="text"
              name="message"
              rows="4"
              className={`form-control w-100 drawer_input ${
                formik.errors.message && formik.touched.message
                  ? "input_error"
                  : ""
              }`}
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
          </div>
        </div>
        <div className="text-end mt-4">
          <button
            type="submit"
            disabled={isLoading}
            className={`btn-sm btn ${
              isLoading ? "btn-light" : "btn-theme"
            } px-4`}
          >
            {isLoading ? <Spin /> : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventCompletedModal;
