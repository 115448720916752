import { Drawer, Space, Spin } from "antd";
import CountrySelect from "components/country-select/CountrySelect";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/city/Actions";
import * as Yup from "yup";

function CityDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const record = editRecord;
  const { loading, country } = useSelector(
    (state) => ({
      loading: state.city?.loading,
      country: state.city?.country,
    }),
    shallowEqual
  );

  const {
    name_en = "",
    name_ar = "",
    name_de = "",
    name_ru = "",
    image = "",
    id = null,
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} City`;
  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => formik.setValues({ ...initialValues });
  const resetForm = () => formik.resetForm();
  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    image: image || "",
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
  });

  const onSubmit = async (values) => {
    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(
          { ...values, countryId: country.id },
          setDrawer,
          resetForm,
          pagination
        );
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={500}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!record && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">Country</span>
                  <CountrySelect
                    isDisabled={true}
                    values={{
                      value: country.code || "us",
                      label: (
                        <div>
                          {" "}
                          <img
                            className="avatar avatar-xss avatar-circle me-2"
                            src={`../assets_modules/flag-icon-css/flags/1x1/${
                              country?.code?.toLowerCase() || "us"
                            }.svg`}
                            alt=" Flag loading..."
                          />
                          {country.name_en || "United States"}
                        </div>
                      ),
                    }}
                    defaultNameRedux={country?.name_en || ""}
                    defaultValueRedux={country?.code || ""}
                    className={"w-100"}
                    style={{
                      border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                    }}
                  />
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">
                    Name <span className="form-label-secondary">(English)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_en"
                    className={`form-control ${
                      formik.errors.name_en && formik.touched.name_en
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_en}
                    placeholder="Enter city name in (English)"
                  />
                  {formik.errors.name_en && formik.touched.name_en && (
                    <ErrorMessage message={formik.errors.name_en} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Arabic)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ar"
                    className={`form-control ${
                      formik.errors.name_ar && formik.touched.name_ar
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ar}
                    placeholder="Enter city name in (Arabic)"
                  />
                  {formik.errors.name_ar && formik.touched.name_ar && (
                    <ErrorMessage message={formik.errors.name_ar} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Russian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ru"
                    className={`form-control ${
                      formik.errors.name_ru && formik.touched.name_ru
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ru}
                    placeholder="Enter city name in (Russian)"
                  />
                  {formik.errors.name_ru && formik.touched.name_ru && (
                    <ErrorMessage message={formik.errors.name_ru} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(German)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_de"
                    className={`form-control ${
                      formik.errors.name_de && formik.touched.name_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_de}
                    placeholder="Enter city name in (German)"
                  />
                  {formik.errors.name_de && formik.touched.name_de && (
                    <ErrorMessage message={formik.errors.name_de} />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default CityDrawer;
