import { notification } from "antd";
import axios from "axios";
import Loader from "components/loader/Loader";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "./main.style.css";
import * as _redux from "./redux";
import store from "./redux/store";
import { routes } from "./routes/routes";
import { NOTIFICATION } from "./utils/constants/contants";

let persistor = persistStore(store);
const { DURATION: duration, PLACEMENT: placement } = NOTIFICATION;

function Main() {
  notification.config({ placement, duration, maxCount: 1 });

  _redux.SetupAxios(axios, store);

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <RouterProvider router={routes} />
          </PersistGate>
        </Provider>
      </React.Suspense>
    </>
  );
}

export default Main;
