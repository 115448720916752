export const DefaultLanguages = [
  {
    label: <span className="required">English </span>,
    value: "en",
  },
  { label: "Arabic", value: "ar" },
  { label: "German", value: "de" },
  { label: "Russian", value: "ru" },
  { label: "Ukrainian", value: "uk" },

];

export const UserRole = {
  Admin: "admin",
  Doctor: "doctor",
  Author:"author",
  Editor:"editor",
  CustomerSupport: "customer-support",
};
