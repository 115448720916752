import { Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "components/button/Buttons";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/diseases/Actions";

// Function to deserialize records array
const deserializeRecords = (records) => {
  return records.map(record => ({
    ...record,
    slug: JSON.parse(record.slug), // Convert string back to nested object
    treatmentDisease: [record.treatmentDisease.map(subItem => JSON.parse(subItem))] // Convert string back to nested array of objects
  }));
};

function DiseasesTable(props) {
  const {
    setEdit,
    remove,
    setRemove,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    setDrawer,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();

  // const { records, count, loading } = useSelector(
  //   (state) => ({
  //     records: state.disease?.records || [],
  //     count: state.disease?.count || 0,
  //     loading: state.disease?.loading,
  //   }),
  //   shallowEqual
  // );
  
  // Selector function to retrieve data from Redux state and deserialize it
  const selectDeserializedData = (state) => {
    const { records, count, loading } = state.disease || {};
    return {
      records: deserializeRecords(records) || [],
      count: count || 0,
      loading: loading,
    };
  };

  const { records, count, loading } = useSelector(selectDeserializedData, shallowEqual);


  const fetchDiseases = useCallback(() => {
    dispatch(actions.getAll(pagination));
  }, 
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch]);

  useEffect(() => {
    fetchDiseases();
  },
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText]);

  const handleDelete = () => {
    dispatch(actions.remove(remove?.id, setRemove, pagination));
  };

  const columns = [
    {
      title: (
        <>
          Disease <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (name_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: (
        <>
          Treatment <span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (name_en, entity) => {
        const treatments = entity?.treatmentDisease[0] || [];
        if (treatments.length > 0) {
          return (
            <>
              {treatments.map((treatment, index) => (
                <span key={index}>
                  {index > 0 && ", "}
                  {treatment.treatment.name_en} 
                </span>
              ))}
            </>
          );
        } else {
          return null;
        }
      },
      
      
    },
    {
      title: (
        <>
          Specialty <span className="form-label-secondary">(English)</span>{" "}
        </>
      ),
      dataIndex: "name_ru",
      key: "name_ru",
      render: (name_en, entity) => {
        return <>{entity?.treatmentDisease[0]?.treatment?.speciality}</>;
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              <span className="nav-link">
                <ViewButton
                  onClick={() => {
                    setView(entity);
                    setDrawer(true);
                  }}
                />
              </span>

              <span className="nav-link mx-1">
                <EditButton
                  onClick={() => {
                    setEdit(entity);
                    setDrawer(true);
                  }}
                />
              </span>
              <span className="nav-link ">
                <DeleteButton onClick={() => setRemove(entity)} />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: 'max-content' }}

            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {},
              };
            }}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={remove}
        onClose={setRemove}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default DiseasesTable;
