import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { settingsSlice } from "./Reducer";
const { actions } = settingsSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAll = () => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll()
    .then((response) => {
      const records  = response.data;
      dispatch(actions.getAll(records));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAll: false })));
};


export const update = (id, checked) => (dispatch) => {
  dispatch(actions.setLoading({ update: true }));
  return requestFromServer
    .update(id, { autoAssignRequest: checked }) 
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
        dispatch(getAll()); 
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ update: false })));
};
