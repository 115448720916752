import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";

function Expertise({ formik, view }) {
  const { Segment, language } = LanguageSegment();

  return (
    <>
      <div className="language-segmented my-2 drawer_area_main_div mx-3 px-4">
        {Segment}
      </div>
      <div className="drawer_area_main_div mx-3 my-2 px-3">
        <div className=" ">
          {/* Expertise  */}

          {language === "en" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Expertise{" "}
                  <span className="form-label-secondary">(English)</span>
                </span>
                <RichEditor
                  disabled={view}
                  className="input_error"
                  value={formik.values.expertise_en}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("expertise_en", value)
                  }
                />
                {formik.errors.expertise_en && formik.touched.expertise_en && (
                  <ErrorMessage message={formik.errors.expertise_en} />
                )}
              </div>
            </>
          )}

          {/* Expertise  */}
          {language === "ar" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Expertise{" "}
                  <span className="form-label-secondary">(Arabic)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.expertise_ar}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("expertise_ar", value)
                  }
                />
              </div>
            </>
          )}

          {/* Expertise  */}
          {language === "de" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Expertise{" "}
                  <span className="form-label-secondary">(German)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.expertise_de}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("expertise_de", value)
                  }
                />
              </div>
            </>
          )}

          {/* Expertise  */}
          {language === "ru" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Expertise{" "}
                  <span className="form-label-secondary">(Russian)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.expertise_ru}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("expertise_ru", value)
                  }
                />
              </div>
            </>
          )}

           {/* Expertise  */}
           {language === "uk" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Expertise{" "}
                  <span className="form-label-secondary">(Ukrainian)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.expertise_uk}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("expertise_uk", value)
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Expertise;
