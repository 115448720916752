import React from "react";
import { PlusOutlined } from "@ant-design/icons";

export const IconButton = ({ iconClass, backgroundColor, ...props }) => (
  <i
    {...props}
    className={iconClass}
    style={{
      opacity: 1,
      cursor: "pointer",
      color: "white",
      padding: "8px 10px",
      background: backgroundColor,
      borderRadius: 10,
    }}
  ></i>
);
export const IconButtonResponse = ({ iconClass, backgroundColor, ...props }) => (
  <i
    {...props}
    className={iconClass}
    style={{
      cursor: "pointer",
      color: "black",
      padding: "8px 5px",
      fontSize:"28px"
    }}
  ></i>
);
export const DeleteButton = ({ backgroundColor, ...props }) => (
  <IconButton
    iconClass="bi-trash nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
    
  />
);

export const ApproveButton = (props) => (
  <IconButton
    iconClass="bi-check-circle nav-icon "
    backgroundColor="#132144"
    title="Approve"
    {...props}
  />
);

export const RejectButton = (props) => (
  <IconButton
    iconClass="bi-x-circle nav-icon "
    backgroundColor="#f30301"
    title="Reject"
    {...props}
  />
);

export const DeleteButtonResponse = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-trash nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const CalenderButtonResponse = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-calendar3 nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const CheckButtonResponse = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-check-square nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const EditReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-person nav-icon fs-3"
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const DetailReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi bi-tv nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const HistoryReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi bi-clock-history nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const InfoCardButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi bi-card-heading nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const EditButton = (props) => (
  <IconButton
    iconClass="bi-pencil-fill nav-icon "
    backgroundColor="#132144"
    {...props}
  />
);

export const ViewButton = (props) => (
  <IconButton
    iconClass="bi bi-eye-fill nav-icon"
    backgroundColor="#808080"
    {...props}
  />
);

export const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
