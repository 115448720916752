import axios from "axios";
import { ApiUrl } from "services/api";

const { Content: Api } = ApiUrl;

export const getAll = async ({ query }) =>
  axios[Api.getAll.method](Api.getAll.url(query));

export const getAllDraft = async ({ query }) =>
  axios[Api.getAllDraft.method](Api.getAllDraft.url(query));


export const create = async (data) =>
  axios[Api.create.method](Api.create.url(), data);

export const bulk = async (data) =>
  axios[Api.bulk.method](Api.bulk.url(), data);

export const remove = async (id) =>
  axios[Api.remove.method](Api.remove.url(id));

export const update = async (id, data) =>
  axios[Api.update.method](Api.update.url(id), data);

export const updateDraft = async (id, data) =>
  axios[Api.updateDraft.method](Api.updateDraft.url(id), data);
