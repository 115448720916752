export const PROJECT = {
  NAME: "BESTCLINICABROAD",
};

export const AVATAR_URL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJh_YSIh_3ajijnUz5IaJx5U8CVFhPdkYIVg&usqp=CAU";

export const FALLBACK_IMAGE_URL =
  "https://media.istockphoto.com/id/1057525222/vector/flat-line-icon-concept-of-404-error-page-or-file-not-found-icon-file-404-on-laptop-display.jpg?s=170667a&w=0&k=20&c=SAxemESv4uur_kbOmasXDJJk8vAOxcuE9rurlLNrMPM=";

export const ERROR_MESSAGE = {
  INVALID_CREDENTIALS: "Invalid Credentials",
  UPDATE_PASSWORD_ERROR: "Invalid Credentials",
  UPDATE_PROFILE_ERROR: "Profile Update Failed",
};

export const NOTIFICATION = {
  DURATION: 1,
  PLACEMENT: "topLeft",
};

export const SUCCESS_MESSAGE = {
  LOGIN_SUCCESSFULLY: "Login Successfully",
  UPDATE_PASSWORD_SUCCESS: "Password Updated Successfully",
  UPDATE_PROFILE_SUCCESS: "Profile Updated Successfully",
};
export const CRUD_COMMON_MESSAGE = {
  CREATE: "New Record Added Successfully",
  REMOVE: "Record Deleted Successfully",
  UPDATE: "Record Updated Successfully",
  BULKUPLOAD: "Bulk Uploaded Successfully",
};

export const REGEX = {
  domain:
    // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
    /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,

  };
export const MAIL_FORMATE =
  // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
