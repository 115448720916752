import axios from "axios";
import { ApiUrl } from "services/api";

const { Team: Api } = ApiUrl;

export const getAll = async ({ query }) =>
  axios[Api.getAll.method](Api.getAll.url(query));

export const create = async (data) =>
  axios[Api.create.method](Api.create.url(), data);

export const remove = async (id) =>
  axios[Api.remove.method](Api.remove.url(id));

export const update = async (id, data) =>
  axios[Api.update.method](Api.update.url(id), data);
